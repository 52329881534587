.brand-campaign-page {
  background: #f8fafc;
  min-height: 100vh;
  padding: 2rem 0;
}

.brand-campaign-wrapper {
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.brand-campaign-topbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.brand-campaign-nav {
  display: flex;
  gap: 1rem;
}

.brand-campaign-header {
  margin-bottom: 2rem;
}

.brand-campaign-overview {
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  margin: 1.5rem 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.brand-campaign-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.brand-campaign-item {
  padding: 1rem;
  background: #f8fafc;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

.brand-campaign-label {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #64748b;
  margin-bottom: 0.5rem;
}

.brand-campaign-value {
  font-size: 1rem;
  color: #1e293b;
  font-weight: 600;
}

.brand-campaign-value.highlight {
  color: #059669;
}

.brand-campaign-tracking {
  margin-top: 2rem;
}

.brand-campaign-script {
  margin-bottom: 2rem;
}

.brand-campaign-script-header {
  margin-bottom: 1rem;
}

.brand-campaign-script-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1e293b;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.brand-campaign-code {
  position: relative;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-top: 1rem;
  overflow: hidden;
}

.brand-campaign-copy {
  position: absolute !important;
  top: 0.5rem !important;
  right: 0.5rem !important;
  z-index: 1;
}

@media (max-width: 768px) {
  .brand-campaign-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .brand-campaign-nav {
    flex-direction: column;
  }

  .brand-campaign-code pre {
    padding-right: 4rem;
  }
}

@media (max-width: 480px) {
  .brand-campaign-grid {
    grid-template-columns: 1fr;
  }
}

.campaign-page-container {
  background: #f8fafc;
  min-height: 100vh;
  padding: 2rem 0;
}

.campaign-header {
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e2e8f0;
}

.campaign-header h1 {
  color: #1a202c;
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
}

.campaign-header p {
  color: #4a5568;
  font-size: 1.125rem;
  line-height: 1.6;
}

.brand-campaign-info {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.campaign-details-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f1f5f9;
}

.campaign-details-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #0f172a;
  margin: 0;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.detail-item {
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #e2e8f0;
}

.detail-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.detail-label {
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #64748b;
  margin-bottom: 0.75rem;
  font-weight: 500;
}

.detail-value {
  font-size: 1.25rem;
  color: #1e293b;
  font-weight: 600;
  line-height: 1.4;
}

.detail-value.payout {
  color: #059669;
}

.detail-value.duration {
  font-size: 1.125rem;
}

.tracking-scripts {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #e2e8f0;
}

.script-container {
  background: #f7fafc;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1rem;
  position: relative;
}

.script-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.script-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #2d3748;
}

.code-block {
  background: #2d3748;
  color: #e2e8f0;
  padding: 1.25rem;
  border-radius: 6px;
  font-family: monospace;
  font-size: 0.875rem;
  line-height: 1.5;
  overflow-x: auto;
}

.copy-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: white;
  border: 1px solid #e2e8f0;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.copy-button:hover {
  background: #edf2f7;
}

.action-buttons {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #e2e8f0;
  display: flex;
  gap: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .campaign-header {
    padding: 1.5rem;
  }

  .details-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .brand-campaign-info {
    padding: 1.5rem;
  }

  .action-buttons {
    flex-direction: column;
  }

  .action-buttons button {
    width: 100%;
  }
}

/* Loading and Error States */
.loading-state,
.error-state {
  text-align: center;
  padding: 3rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.error-state {
  color: #e53e3e;
}

/* Add these new styles */
.campaign-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e2e8f0;
}

.campaign-navigation {
  display: flex;
  gap: 1rem;
}

.campaign-metrics {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.metrics-header {
  margin-bottom: 1.5rem;
}

.metrics-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a202c;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

.metric-card {
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 12px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  border: 1px solid #e2e8f0;
}

.metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.metric-label {
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.metric-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .metrics-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .campaign-navigation {
    flex-direction: column;
    width: 100%;
  }

  .campaign-navigation button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .metrics-grid {
    grid-template-columns: 1fr;
  }
}

/* Update/replace the campaign-metrics and campaign-details sections with: */
.campaign-overview {
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  margin: 1.5rem 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.overview-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.overview-item {
  padding: 1rem;
  background: #f8fafc;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

.overview-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.overview-label {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #64748b;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.overview-value {
  font-size: 1rem;
  color: #1e293b;
  font-weight: 600;
  line-height: 1.4;
}

.overview-value.highlight {
  color: #059669;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .overview-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .overview-grid {
    grid-template-columns: 1fr;
  }
  
  .campaign-overview {
    padding: 1rem;
  }
  
  .overview-item {
    padding: 0.75rem;
  }
} 
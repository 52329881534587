/* Core Layout */
.brand-dash-container {
  display: flex;
  min-height: 100vh;
  background: #f8fafc;
  padding: 1rem;
  gap: 1.5rem;
}

/* Main Content Area */
.brand-dash-main {
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* Section Styling */
.brand-dashboard-section {
  background: white;
  border-radius: 0.75rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

/* Section Header */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.section-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

/* Grid Layouts */
.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1rem;
  padding: 1.5rem;
}

.partnered-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1.5rem;
}

/* Card Components */
.metric-card {
  background: white;
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid #e5e7eb;
}

.partner-card {
  background: white;
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid #e5e7eb;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .brand-dash-container {
    padding: 0.5rem;
  }
  
  .metrics-grid,
  .partnered-grid {
    grid-template-columns: 1fr;
  }
  
  .section-header {
    padding: 1rem;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  .brand-dash-container {
    background: #0f172a;
  }
  
  .brand-dashboard-section {
    background: #1e293b;
  }
  
  .section-title {
    color: #f8fafc;
  }
}

/* Main Dashboard Layout */
.brand-dash-container {
  display: flex;
  min-height: 100vh;
  background: #f8fafc;
  position: relative;
}

/* Sidebar Styles */
.brand-dash-sidebar {
  width: 280px;
  background: white;
  padding: 2rem;
  border-right: 1px solid #e2e8f0;
  position: sticky;
  height: calc(100vh - 8rem);
  left: 0;
  top: 4rem;
  z-index: 10;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  border-radius: 12px;
  margin: 0 1rem;
}

.sidebar-button {
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  border: none;
  background: transparent;
  color: #4b5563;
  font-size: 0.95rem;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.sidebar-button:hover {
  background: #f1f5f9;
  color: #0f172a;
}

.sidebar-button.active {
  background: #f1f5f9;
  color: #4f46e5;
  font-weight: 600;
}

/* Main Content Area */
.brand-dash-main {
  flex: 1;
  padding: 2rem;
  min-height: 100vh;
  overflow-y: auto;
  margin-left: 20px;
}

.brand-dash-content {
  max-width: 1200px;
  margin: 0 auto;
}

.brand-dashboard-section {
  flex: 1;  /* Takes up available space */
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 1rem;
}

.section-title {
  font-size: 1.1rem;
  color: #111827;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e5e7eb;
}

/* Grid Layout */
.partnered-creators-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

/* Partner Card */
.partner-card {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  padding: 0.75rem;
}

/* Header Section */
.partner-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.partner-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.partner-info h3 {
  font-size: 0.9rem;
  font-weight: 500;
  color: #111827;
  margin: 0;
}

.partner-platform {
  font-size: 0.75rem;
  color: #6b7280;
  display: block;
  margin-top: 0.25rem;
}

/* Offer Details */
.offer-details {
  padding: 0.4rem 0;
  margin-bottom: 0.4rem;
}

.offer-details h4 {
  font-size: 0.65rem;
  text-transform: uppercase;
  color: #6b7280;
  letter-spacing: 0.025em;
  margin: 0 0 0.2rem 0;
}

.offer-title {
  font-size: 0.7rem;
  color: #374151;
  margin: 0 0 0.2rem 0;
  line-height: 1.2;
}

.offer-price {
  font-size: 0.8rem;
  font-weight: 600;
  color: #059669;
  margin: 0 0 0.2rem 0;
}

.offer-status {
  font-size: 0.7rem;
  color: #6b7280;
  margin: 0;
}

/* Action Buttons */
.partner-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.75rem;
}

.view-profile-btn,
.message-btn {
  flex: 1;
  font-size: 0.75rem;
  padding: 0.4rem 0.5rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.view-profile-btn {
  background: #f3f4f6;
  color: #374151;
}

.message-btn {
  background: #4f46e5;
  color: white;
}

/* Loading and Empty States */
.no-partners {
  grid-column: 1 / -1;
  text-align: center;
  padding: 1rem;
  color: #6b7280;
  font-size: 0.9rem;
}

.loading-spinner {
  grid-column: 1 / -1;
  text-align: center;
  padding: 1rem;
}

/* Campaign Section */
.campaigns-section {
  padding: 1rem;
  width: 100%;
}

.campaigns-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  margin: 0 auto;
}

.brand-campaign-card {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.brand-campaign-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.brand-campaign-title h3 {
  margin: 0;
  font-size: 1.5rem;  /* Increased font size */
  color: #111827;
  font-weight: 600;
}

.brand-campaign-dates {
  display: block;
  font-size: 0.95rem;
  color: #6b7280;
  margin-top: 0.5rem;
}

.brand-edit-btn {
  padding: 0.5rem 1rem;
  background: #f3f4f6;
  border: none;
  border-radius: 6px;
  color: #374151;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.brand-edit-btn:hover {
  background: #e5e7eb;
}

.brand-campaign-details {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.brand-detail-row {
  display: flex;
  align-items: baseline;
  gap: 2rem;          /* Increased gap */
}

.brand-detail-label {
  min-width: 140px;   /* Increased min-width */
  font-weight: 500;
  color: #4b5563;
  font-size: 0.95rem;
}

.brand-tag-btn {
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  background: #4f46e5;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.brand-tag-btn:hover {
  background: #4338ca;
}

.brand-campaign-tags {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e5e7eb;
}

.brand-tag-section {
  margin-bottom: 1.5rem;
}

.brand-tag-section h4 {
  margin: 0 0 0.5rem 0;
  color: #111827;
}

.brand-tag-description {
  margin: 0.5rem 0;
  color: #6b7280;
  font-size: 0.9rem;
}

.brand-code-box {
  background: #f8fafc;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  padding: 1rem;
  margin: 0.5rem 0;
  position: relative;
}

.brand-code-box pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;
  font-family: monospace;
  font-size: 0.9rem;
  color: #334155;
}

.brand-copy-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem 1rem;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.2s;
}

.brand-copy-btn:hover {
  background: #f3f4f6;
  border-color: #d1d5db;
}

/* Partnered Dashboard Content */
.partnered-dashboard-content {
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.partnered-section {
  width: 100%;
  padding: 1rem;
}

.partnered-title {
  font-size: 1.25rem;
  color: #111827;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e5e7eb;
}

/* Grid Layout */
.partnered-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  padding: 16px;
}

/* Partner Card */
.partnered-card {
  background: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e7eb;
}

/* Header Section */
.partnered-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.partnered-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  object-fit: cover;
}

.partnered-creator-info {
  flex: 1;
}

.partnered-creator-info h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #1f2937;
}

.offer-title {
  font-size: 13px;
  color: #4b5563;
  margin-top: 2px;
}

.partnered-details {
  margin: 12px 0;
  font-size: 13px;
}

.partnered-detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.detail-label {
  color: #6b7280;
}

.detail-value {
  font-weight: 500;
}

.message-preview {
  font-style: italic;
  color: #4b5563;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.partnered-actions {
  display: flex;
  gap: 8px;
  margin: 12px 0;
}

.partnered-view-btn,
.partnered-message-btn {
  flex: 1;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.partnered-view-btn {
  background: #f3f4f6;
  color: #1f2937;
}

.partnered-message-btn {
  background: #3b82f6;
  color: white;
}

.partnered-view-btn:hover {
  background: #e5e7eb;
}

.partnered-message-btn:hover {
  background: #2563eb;
}

.offer-status-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  font-size: 11px;
}

.partnered-status {
  padding: 3px 6px;
  border-radius: 4px;
  font-weight: 500;
}

.partnered-status.accepted {
  background-color: #e6f4ea;
  color: #1e7e34;
}

.partnered-status.pending {
  background-color: #fff3dc;
  color: #b25e09;
}

.last-updated {
  color: #6b7280;
}

.partnered-loading {
  text-align: center;
  padding: 20px;
  color: #6b7280;
}

.partnered-empty {
  text-align: center;
  padding: 20px;
  color: #6b7280;
  font-style: italic;
}

.partnered-title {
  font-size: 20px;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 16px;
  padding: 0 16px;
}

/* Brand Metrics Section Styles */
.brand-metrics-container {
  padding: 1.5rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.brand-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
}

.metric-card {
  background: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  height: 160px;
  transition: all 0.2s ease;
  border: 1px solid #f1f5f9;
}

.metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
}

.metric-card.primary {
  background: linear-gradient(135deg, #2563eb, #1d4ed8);
  border: none;
}

.metric-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.metric-title {
  font-size: 15px;
  font-weight: 500;
  color: #64748b;
  margin: 0;
}

.metric-card.primary .metric-title {
  color: rgba(255, 255, 255, 0.9);
}

.metric-value {
  font-size: 32px;
  font-weight: 600;
  color: #0f172a;
  margin: 16px 0;
}

.metric-card.primary .metric-value {
  color: white;
}

.metric-trend {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  font-weight: 500;
}

.metric-trend.positive {
  color: #059669;
}

.metric-trend.negative {
  color: #dc2626;
}

.metric-trend.neutral {
  color: #64748b;
}

.metric-card.primary .metric-trend {
  color: rgba(255, 255, 255, 0.9);
}

.trend-arrow {
  font-weight: bold;
}

.trend-period {
  color: #94a3b8;
  font-weight: normal;
}

.metric-card.primary .trend-period {
  color: rgba(255, 255, 255, 0.7);
}

/* Loading Spinner */
.brand-metrics-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.brand-metrics-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #4f46e5;
  border-radius: 50%;
  animation: brand-spin 1s linear infinite;
  margin-bottom: 1rem;
}

.brand-metrics-loading p {
  color: #6b7280;
  font-size: 0.9rem;
}

@keyframes brand-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Tab Styling */
.brand-metrics-tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 0.5rem;
}

.brand-metric-tab {
  padding: 0.75rem 1.25rem;
  font-size: 0.95rem;
  font-weight: 500;
  color: #6b7280;
  background: transparent;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.brand-metric-tab:hover {
  color: #4f46e5;
  background: #f3f4f6;
}

.brand-metric-tab.active {
  color: #4f46e5;
  background: #f3f4f6;
  font-weight: 600;
}

/* Update campaign card metrics styling */
.brand-campaign-metrics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;          /* Increased gap */
  padding: 1.5rem 0;  /* Increased padding */
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 1.5rem;
}

.brand-campaign-metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.brand-metric-label {
  font-size: 0.875rem;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  margin-bottom: 0.5rem;
}

.brand-metric-number {
  font-size: 1.5rem;  /* Increased font size */
  font-weight: 600;
  color: #111827;
}

/* Update campaign footer for cleaner look */
.brand-campaign-footer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-top: 0.75rem;
}

.brand-detail-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.25rem 0;
}

.brand-detail-label {
  font-size: 0.85rem;
  color: #6b7280;
  min-width: 100px;
  font-weight: 500;
}

.brand-detail-row span:last-child {
  font-size: 0.95rem;
  color: #111827;
  font-weight: 500;
}

/* Add highlight for promo code */
.brand-detail-row:last-child span:last-child {
  background: #f3f4f6;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-family: monospace;
  letter-spacing: 0.5px;
}

/* Make it stack on smaller screens */
@media (max-width: 1200px) {
  .dashboard-content {
    flex-direction: column;
  }
  
  .partnered-section {
    width: 100%;
    margin-left: 0;
    min-width: unset;
  }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .brand-dash-container {
    padding-left: 0;
    padding-top: 1rem;
  }

  .brand-dash-sidebar {
    position: static;
    width: 100%;
    height: auto;
    padding: 1rem;
    margin: 0;
    border-right: none;
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    gap: 1rem;
    overflow-x: auto;
    top: 0;
  }

  .brand-dash-main {
    margin-left: 0;
    padding: 1rem;
  }

  .brand-dash-content {
    padding: 1rem;
  }

  .campaigns-list {
    max-width: 100%;
  }

  .brand-campaign-metrics {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

.brand-dashboard-wrapper {
  display: flex;
  min-height: 100vh;

  padding: 3rem;
  gap: 3rem;
  width: 100%;
  margin: 0 auto;
}

.brand-dashboard-sidebar {
  width: 200px;
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  height: fit-content;
}

.brand-dashboard-main {
  flex: 1;
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-width: 0;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .brand-dashboard-wrapper {
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
  }

  .brand-dashboard-sidebar {
    width: 100%;
    padding: 1rem;
  }

  .brand-dashboard-main {
    padding: 1rem;
  }
}

/* Responsive adjustment */
@media (max-width: 768px) {
  .partnered-grid {
    grid-template-columns: 1fr;
  }
}

/* Responsive adjustment */
@media (max-width: 1200px) {
  .partnered-grid {
    grid-template-columns: 1fr;
  }
  
  .partnered-card {
    min-width: 0; /* Remove min-width on smaller screens */
  }
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding: 0 0 16px 0;
    border-bottom: 1px solid #e5e7eb;
}

.section-header h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #111827;
}

.create-btn {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #4f46e5;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    text-decoration: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.create-btn:hover {
    background-color: #4338ca;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.create-btn:active {
    transform: translateY(0);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.create-btn::before {
    content: "+";
    margin-right: 8px;
    font-size: 16px;
    font-weight: 600;
}

/* Optional: Add responsive styles */
@media (max-width: 640px) {
    .section-header {
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
    }

    .create-btn {
        width: 100%;
        justify-content: center;
    }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .campaigns-list {
        max-width: 100%;
    }

    .brand-campaign-metrics {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
    }
}

@media (max-width: 640px) {
    .brand-campaign-card {
        padding: 1.5rem;
    }

    .brand-campaign-metrics {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .brand-detail-row {
        flex-direction: column;
        gap: 0.5rem;
    }

    .brand-detail-label {
        min-width: auto;
    }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  .dashboard-container {
    background: #0f172a;
  }

  .dashboard-sidebar {
    background: #1e293b;
    border-color: #334155;
  }

  .sidebar-button {
    color: #94a3b8;
  }

  .sidebar-button:hover {
    background: #334155;
    color: #f8fafc;
  }

  .sidebar-button.active {
    background: #334155;
    color: #818cf8;
  }

  .brand-dashboard-section {
    background: #1e293b;
  }

  .section-title {
    color: #f8fafc;
  }
}

/* Metrics Dashboard */
.metrics-dashboard {
  width: 100%;
  padding: 0; /* Remove padding here */
}

.metrics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
}

.time-filter {
  display: flex;
  gap: 0.5rem;
}

.time-filter-btn {
  padding: 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background: white;
  color: #64748b;
  cursor: pointer;
}

/* Metrics Grid */
.metrics-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin: 24px 0;
  padding: 0 4px;
}

/* Metric Cards */
.metric-card {
  background: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  height: 160px;
  transition: all 0.2s ease;
  border: 1px solid #f1f5f9;
}

.metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
}

.metric-card.primary {
  background: linear-gradient(135deg, #2563eb, #1d4ed8);
  border: none;
}

.metric-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.metric-title {
  font-size: 15px;
  font-weight: 500;
  color: #64748b;
  margin: 0;
}

.metric-card.primary .metric-title {
  color: rgba(255, 255, 255, 0.9);
}

.metric-value {
  font-size: 32px;
  font-weight: 600;
  color: #0f172a;
  margin: 16px 0;
}

.metric-card.primary .metric-value {
  color: white;
}

.metric-trend {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  font-weight: 500;
}

.metric-trend.positive {
  color: #059669;
}

.metric-trend.negative {
  color: #dc2626;
}

.metric-trend.neutral {
  color: #64748b;
}

.metric-card.primary .metric-trend {
  color: rgba(255, 255, 255, 0.9);
}

.trend-arrow {
  font-weight: bold;
}

.trend-period {
  color: #94a3b8;
  font-weight: normal;
}

.metric-card.primary .trend-period {
  color: rgba(255, 255, 255, 0.7);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .metrics-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .metrics-grid {
    grid-template-columns: 1fr;
  }
}

/* Charts Section */
.metrics-charts {
  display: block;
  margin-top: 2rem;
  width: 100%;
}

.chart-container {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.chart-container h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1.1rem;
  color: #0f172a;
  font-weight: 600;
}

/* Campaign Performance List */
.campaign-performance-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.performance-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 8px;
  transition: background-color 0.2s;
}

.performance-item:hover {
  background: #f1f5f9;
}

.campaign-info {
  flex: 1;
  min-width: 0;
}

.campaign-info h4 {
  margin: 0;
  font-size: 0.9rem;
  color: #0f172a;
  font-weight: 500;
}

.campaign-info p {
  margin: 0.25rem 0 0 0;
  font-size: 0.8rem;
  color: #64748b;
}

.campaign-stats {
  display: flex;
  gap: 2rem;
  margin-left: 1rem;
}

.stat {
  text-align: right;
  min-width: 80px;
}

.stat-value {
  font-size: 0.9rem;
  font-weight: 500;
  color: #0f172a;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .metrics-charts {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .performance-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .campaign-stats {
    width: 100%;
    margin-left: 0;
    justify-content: space-between;
  }

  .stat {
    text-align: left;
    min-width: 0;
  }
}

.partnerships-section {
  padding: 24px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.partnerships-section h2 {
  margin-bottom: 24px;
  font-size: 24px;
  color: #333;
}

.partnerships-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.partnership-card {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
}

.partnership-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
  padding-bottom: 12px;
}

.creator-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.partnership-header h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
  flex-grow: 1;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

.status-badge.approved {
  background-color: #e3f9e5;
  color: #1b873f;
}

.status-badge.pending {
  background-color: #fff3dc;
  color: #d97706;
}

.status-badge.rejected {
  background-color: #fee2e2;
  color: #dc2626;
}

.partnership-details {
  margin: 16px 0;
}

.partnership-details p {
  margin: 8px 0;
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  gap: 8px;
}

.partnership-details strong {
  color: #333;
  min-width: 100px;
}

.partnership-details span {
  color: #666;
}

.partnership-actions {
  display: flex;
  gap: 12px;
  margin-top: 16px;
  border-top: 1px solid #eee;
  padding-top: 16px;
}

.view-profile-btn,
.message-btn {
  flex: 1;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
}

.view-profile-btn {
  background-color: #f3f4f6;
  color: #374151;
}

.message-btn {
  background-color: #2563eb;
  color: white;
}

/* Loading and Error States */
.partnerships-section .error-message {
  color: #dc2626;
  padding: 16px;
  background-color: #fee2e2;
  border-radius: 6px;
  margin-top: 16px;
}

.partnerships-section div:empty {
  text-align: center;
  color: #666;
  padding: 32px;
  background-color: #f9fafb;
  border-radius: 6px;
  margin-top: 16px;
}

/* Partnerships/Affiliates Section */
.brand-affiliates-section {
  padding: 24px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.brand-affiliates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.brand-affiliate-card {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
}

.brand-affiliate-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
  padding-bottom: 12px;
}

.brand-affiliate-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.brand-affiliate-header h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
  flex-grow: 1;
}

.brand-affiliate-status {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

.brand-affiliate-details {
  margin: 16px 0;
}

.brand-affiliate-details p {
  margin: 8px 0;
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  gap: 8px;
}

.brand-affiliate-details strong {
  color: #333;
  min-width: 100px;
}

.brand-affiliate-details span {
  color: #666;
}

.brand-affiliate-actions {
  display: flex;
  gap: 12px;
  margin-top: 16px;
  border-top: 1px solid #eee;
  padding-top: 16px;
}

.brand-affiliate-view-btn,
.brand-affiliate-message-btn {
  flex: 1;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
}

.brand-affiliate-view-btn {
  background-color: #f3f4f6;
  color: #374151;
}

.brand-affiliate-message-btn {
  background-color: #2563eb;
  color: white;
}

.brand-affiliates-intro {
  background-color: #f0f9ff;
  border-left: 4px solid #2563eb;
  padding: 16px 20px;
  margin: 24px 0 32px 0; /* Increased margin top and bottom */
  border-radius: 0 8px 8px 0;
}

.brand-affiliates-intro p {
  color: #1e3a8a;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}

.brand-affiliates-section h2 {
  font-size: 24px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e5e7eb;
}

.brand-affiliates-intro {
  background-color: #f0f9ff;
  border-left: 4px solid #2563eb;
  padding: 16px 20px;
  margin: 24px 0 32px 0; /* Increased margin top and bottom */
  border-radius: 0 8px 8px 0;
}

.brand-affiliates-intro p {
  color: #1e3a8a;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}

.brand-affiliate-social-stats {
  display: flex;
  gap: 16px;
  margin: 8px 0 16px 16px;
  flex-wrap: wrap;
}

.social-stat {
  font-size: 13px;
  color: #666;
  display: flex;
  align-items: center;
  gap: 4px;
}

.brand-affiliate-metrics {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-top: 8px;
  margin-left: 16px;
}

.metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  background: #f8fafc;
  border-radius: 6px;
}

.metric-label {
  font-size: 12px;
  color: #64748b;
}

.metric-value {
  font-size: 16px;
  font-weight: 600;
  color: #334155;
}

.brand-campaign-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #edf2f7;
}

.brand-view-btn {
  padding: 0.5rem 1rem;
  background-color: #3182ce;
  color: white;
  border-radius: 0.375rem;
  font-weight: 500;
  transition: background-color 0.2s;
}

.brand-view-btn:hover {
  background-color: #2c5282;
}
/* Align the page content */
.form-page {
    display: flex;
    justify-content: center;
    align-items: center;
 
}


/* Align the page content */
.form-page {
    display: flex;
    justify-content: center;
    align-items: center;
 
    padding: 40px;
}

/* Container for the form */
.form-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px; /* Restrict the width */
}

/* Style the form elements */
form input,
form select,
form button {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Style the submit button */
form button[type="submit"] {
    background-color: #000000;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

form button[type="submit"]:hover {
    background-color: #333333;
}

form button[type="submit"]:active {
    background-color: #1a1a1a;
}

/* Style the form elements */
form input,
form select,
form button {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Style the submit button */
form button {
    background-color: #ff6f61;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

form button:hover {
    background-color: #e65c4f;
}

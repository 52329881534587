.admin-hub {
    display: flex;
    min-height: calc(100vh - 64px); /* Adjust based on your header height */
    background-color: #f5f6fa;
}

.admin-sidebar {
    width: 250px;
    background-color: white;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    flex-shrink: 0;
}

.admin-sidebar h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #2d3748;
    font-weight: 600;
}

.admin-sidebar nav {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.admin-nav-button {
    padding: 12px 16px;
    text-align: left;
    background: none;
    border: none;
    border-radius: 8px;
    color: #4a5568;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.admin-nav-button:hover {
    background-color: #f7fafc;
    color: #3182ce;
}

.admin-nav-button.active {
    background-color: #ebf8ff;
    color: #3182ce;
    font-weight: 500;
}

.admin-content {
    flex-grow: 1;
    padding: 2rem;
    overflow-y: auto;
}

.admin-logo {
    padding: 0 24px 24px;
    border-bottom: 1px solid #e5e7eb;
}

.admin-logo h2 {
    margin: 0;
    color: #1a1a1a;
    font-size: 24px;
}

.admin-nav {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.nav-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 8px;
    border: none;
    background: none;
    color: #4b5563;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: left;
    width: 100%;
}

.nav-item:hover {
    background: #f1f5f9;
    color: #2563eb;
}

.nav-item.active {
    background: #eff6ff;
    color: #2563eb;
    font-weight: 500;
}

.approval-badge {
    background: #ef4444;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
}

.admin-header {
    background: white;
    padding: 16px 24px;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.current-section {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
}

.admin-profile {
    display: flex;
    align-items: center;
    gap: 12px;
}

.admin-name {
    font-weight: 500;
}

.profile-avatar {
    width: 32px;
    height: 32px;
    background: #f1f5f9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Specific styles for the creator profiles section */
.creator-profiles-section {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    padding: 1.5rem;
}

.creator-profiles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-top: 1.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .admin-hub {
        flex-direction: column;
    }
    
    .admin-sidebar {
        width: 100%;
        padding: 1rem;
    }
    
    .admin-content {
        padding: 1rem;
    }
    
    .creator-profiles-grid {
        grid-template-columns: 1fr;
    }
} 
.creator-payments {
    padding: 32px;
    max-width: 1200px;
    margin: 0 auto;
}

.payments-header {
    margin-bottom: 32px;
}

.payments-header h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 8px 0;
    color: #1a202c;
}

.payments-header p {
    color: #4a5568;
    margin: 0;
}

.earnings-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
}

.earnings-card {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e2e8f0;
}

.earnings-card-header {
    margin-bottom: 24px;
}

.earnings-card-header h2 {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 8px 0;
    color: #2d3748;
}

.payout-schedule {
    font-size: 14px;
    color: #718096;
}

.balance-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
}

.balance-item {
    padding: 16px;
    background: #f7fafc;
    border-radius: 8px;
    border: 1px solid #edf2f7;
}

.balance-item.pending {
    background: #fff8f1;
    border-color: #feebc8;
}

.balance-label {
    display: block;
    font-size: 14px;
    color: #4a5568;
    margin-bottom: 4px;
}

.balance-amount {
    display: block;
    font-size: 24px;
    font-weight: 600;
    color: #2d3748;
}

.balance-info {
    display: block;
    font-size: 12px;
    color: #dd6b20;
    margin-top: 4px;
}

.withdraw-button {
    width: 100%;
    padding: 12px;
    background: #3182ce;
    color: white;
    border: none;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-bottom: 24px;
}

.withdraw-button:hover:not(:disabled) {
    background: #2c5282;
}

.withdraw-button:disabled {
    background: #cbd5e0;
    cursor: not-allowed;
}

.transactions-section {
    margin-top: 24px;
}

.transactions-section h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 16px 0;
    color: #2d3748;
}

.transactions-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.transaction-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background: #f7fafc;
    border-radius: 6px;
}

.transaction-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.transaction-date {
    font-size: 14px;
    color: #4a5568;
}

.transaction-type {
    font-size: 12px;
    color: #718096;
}

.transaction-amount {
    font-weight: 500;
    color: #2d3748;
}

.no-transactions {
    text-align: center;
    color: #718096;
    padding: 24px;
    background: #f7fafc;
    border-radius: 6px;
}

.transaction-brand {
    font-size: 14px;
    color: #4A5568;
    margin-left: 8px;
    font-weight: 500;
}

.transaction-type {
    font-weight: 600;
    color: #2D3748;
}

@media (max-width: 768px) {
    .creator-payments {
        padding: 16px;
    }
    
    .earnings-grid {
        grid-template-columns: 1fr;
    }
} 
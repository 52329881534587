.brand-profile-image {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.image-container {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f6;
    border: 1px dashed #e5e7eb;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.placeholder {
    color: #9ca3af;
    font-size: 12px;
}

.upload-button {
    padding: 6px 12px;
    background-color: #4f46e5;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s;
}

.upload-button:hover {
    background-color: #4338ca;
} 
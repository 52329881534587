.footer {
    background-color: #f8fafc;
    padding: 4rem 0 2rem;
    border-top: 1px solid #e2e8f0;
}

.footer-content {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 24px;
}

/* Desktop Footer */
.footer-sections {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;
    margin-bottom: 3rem;
}

.footer-section h3 {
    color: #1e293b;
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 1.25rem;
}

.footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links li {
    margin-bottom: 0.75rem;
}

.footer-links a {
    color: #64748b;
    text-decoration: none;
    transition: color 0.2s;
    font-size: 0.875rem;
}

.footer-links a:hover {
    color: #000;
}

.contact-info {
    color: #64748b;
    font-size: 0.875rem;
}

.contact-info p {
    margin-bottom: 0.5rem;
}

.social-links {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.social-links a {
    color: #64748b;
    text-decoration: none;
    transition: color 0.2s;
    font-size: 0.875rem;
}

.social-links a:hover {
    color: #000;
}

/* Footer Bottom */
.footer-bottom {
    border-top: 1px solid #e2e8f0;
    padding-top: 2rem;
    margin-top: 2rem;
}

.legal-links {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.legal-links a {
    color: #64748b;
    text-decoration: none;
    font-size: 0.875rem;
    transition: color 0.2s;
}

.legal-links a:hover {
    color: #000;
}

.trust-badges {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.trust-badges span {
    color: #64748b;
    font-size: 0.875rem;
}

.copyright {
    text-align: center;
    color: #64748b;
    font-size: 0.875rem;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .footer {
        padding: 2rem 0 1rem;
    }

    .footer-sections {
        display: none;
    }

    .mobile-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        margin-bottom: 2rem;
    }

    .mobile-footer .social-links {
        margin-top: 0;
    }

    .legal-links {
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: center;
    }

    .trust-badges {
        display: none;
    }

    .copyright {
        margin-top: 1rem;
    }
}

/* Ensure consistent spacing */
* + * {
    margin-top: 0;
} 
.billing-approvals {
    padding: 24px;
    max-width: 1400px;
    margin: 0 auto;
}

.approvals-header {
    margin-bottom: 32px;
}

.header-stats {
    display: flex;
    gap: 24px;
    margin-top: 16px;
}

.stat-item {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.stat-label {
    display: block;
    font-size: 14px;
    color: #666;
    margin-bottom: 4px;
}

.stat-value {
    font-size: 24px;
    font-weight: 600;
    color: #1a1a1a;
}

.approvals-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 24px;
}

.pending-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.billing-card {
    background: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    cursor: pointer;
    transition: all 0.2s ease;
}

.billing-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.billing-card.selected {
    border: 2px solid #2563eb;
}

.billing-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.billing-card-header h3 {
    margin: 0;
    font-size: 16px;
}

.billing-amount {
    font-weight: 600;
    color: #2563eb;
}

.billing-period {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
}

.billing-creators {
    font-size: 13px;
    color: #666;
}

.billing-details {
    background: white;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.action-buttons {
    display: flex;
    gap: 12px;
}

.approve-btn, .reject-btn {
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    transition: all 0.2s ease;
}

.approve-btn {
    background: #22c55e;
    color: white;
}

.approve-btn:hover {
    background: #16a34a;
}

.reject-btn {
    background: #ef4444;
    color: white;
}

.reject-btn:hover {
    background: #dc2626;
}

.details-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.brand-info, .creators-list {
    padding: 16px;
    background: #f8fafc;
    border-radius: 8px;
}

.payment-amounts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.payment-amount {
    font-weight: 600;
    color: #2563eb;
}

.order-amount {
    font-size: 0.875rem;
    color: #6b7280;
}

.creator-payment-item {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
}

.creator-payment-item:last-child {
    border-bottom: none;
}

.creator-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
}

.creator-name {
    font-weight: 500;
}

.campaign-info {
    font-size: 0.875rem;
    color: #4b5563;
}

.loading-state {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    font-size: 1.2rem;
    color: #666;
}

.error-state {
    color: #dc2626;
    padding: 1rem;
    margin: 1rem 0;
    background-color: #fee2e2;
    border: 1px solid #fecaca;
    border-radius: 0.375rem;
}

.error-message {
    color: #dc2626;
    padding: 0.75rem;
    margin: 0.5rem 0;
    background-color: #fee2e2;
    border: 1px solid #fecaca;
    border-radius: 0.375rem;
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.modal-content {
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    max-width: 32rem;
    width: 100%;
}

/* Button styles */
.button {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-weight: 500;
    transition: background-color 0.2s;
}

.button-approve {
    background-color: #22c55e;
    color: white;
}

.button-approve:hover {
    background-color: #16a34a;
}

.button-reject {
    background-color: #ef4444;
    color: white;
}

.button-reject:hover {
    background-color: #dc2626;
}

.button-cancel {
    background-color: #e5e7eb;
    color: #374151;
}

.button-cancel:hover {
    background-color: #d1d5db;
}

/* Card styles */
.billing-card {
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.billing-card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Grid layout */
.billing-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

/* Creator payments list */
.creator-payments {
    margin-top: 0.75rem;
    padding-left: 1.25rem;
}

.creator-payment-item {
    margin-bottom: 0.5rem;
    color: #4b5563;
}

@media (max-width: 1024px) {
    .approvals-grid {
        grid-template-columns: 1fr;
    }
}

/* Container */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}

/* Loading State */
.loading-state {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    color: #6b7280;
}

/* Error States */
.error-state,
.error-message {
    background-color: #fef2f2;
    border: 1px solid #fee2e2;
    color: #991b1b;
    padding: 0.75rem;
    border-radius: 4px;
    margin-bottom: 1rem;
    font-size: 0.875rem;
}

/* Billing Grid */
.billing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
}

/* Billing Card */
.billing-card {
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.billing-header {
    padding: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #e5e7eb;
}

.billing-title {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
}

.billing-period {
    font-size: 0.75rem;
    color: #6b7280;
}

.billing-amount {
    font-size: 1rem;
    font-weight: 600;
    color: #1f2937;
}

/* Payments Section */
.payments-section {
    padding: 0.75rem;
    border-bottom: 1px solid #e5e7eb;
}

.payments-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.payment-item {
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    padding: 0.75rem;
}

.payment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e5e7eb;
}

.creator-name {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1f2937;
}

.payment-amount {
    font-size: 0.875rem;
    font-weight: 600;
    color: #1f2937;
}

/* Payment Details */
.payment-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.detail-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    font-size: 0.75rem;
}

.detail-item {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
}

.detail-item span {
    font-size: 0.7rem;
    color: #6b7280;
}

.detail-item strong {
    font-size: 0.75rem;
    color: #1f2937;
    font-weight: 500;
}

/* Action Buttons */
.card-actions {
    padding: 0.75rem;
    display: flex;
    gap: 0.5rem;
}

.action-button {
    flex: 1;
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.15s ease;
}

.action-button.primary {
    background-color: #f3f4f6;
    border-color: #e5e7eb;
    color: #1f2937;
}

.action-button.primary:hover {
    background-color: #e5e7eb;
}

.action-button.secondary {
    background-color: white;
    border-color: #e5e7eb;
    color: #4b5563;
}

.action-button.secondary:hover {
    background-color: #f9fafb;
}

/* Modal */
.modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 50;
}

.modal-content {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    width: 100%;
    max-width: 28rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.modal-textarea {
    width: 100%;
    margin: 1rem 0;
    padding: 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    min-height: 100px;
    resize: vertical;
}

.modal-textarea:focus {
    outline: none;
    border-color: #9ca3af;
    ring: 1px solid #9ca3af;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1.5rem;
}

/* Typography */
h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 1.5rem;
}

h3 {
    font-size: 1.125rem;
    font-weight: 600;
    color: #1f2937;
    margin: 0;
}

h4 {
    font-size: 0.875rem;
    font-weight: 600;
    color: #4b5563;
    margin: 0 0 0.75rem 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .billing-grid {
        grid-template-columns: 1fr;
    }

    .detail-group {
        grid-template-columns: 1fr;
    }
}

/* Brand Info Section */
.brand-info {
    border-bottom: 1px solid #E5E7EB;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.brand-name {
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 0.5rem;
}

.brand-period {
    color: #6B7280;
    font-size: 0.875rem;
}

.total-amount {
    font-weight: 600;
    color: #059669;
    font-size: 1.125rem;
    margin-top: 0.5rem;
}

/* Creator Payment Section */
.creator-payment {
    background-color: #F9FAFB;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
}

.creator-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
}

.creator-name {
    font-weight: 500;
    color: #111827;
}

.payment-amount {
    color: #059669;
    font-weight: 600;
}

/* Campaign Details */
.campaign-details {
    font-size: 0.875rem;
    color: #4B5563;
    margin-top: 0.75rem;
}

.detail-row {
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0;
    border-bottom: 1px solid #E5E7EB;
}

.detail-label {
    font-weight: 500;
    color: #374151;
}

.detail-value {
    color: #6B7280;
}

/* Status Indicators */
.status-completed {
    color: #059669;
    font-weight: 500;
}

.status-pending {
    color: #D97706;
    font-weight: 500;
}

/* Action Buttons */
.action-buttons {
    display: flex;
    gap: 0.75rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #E5E7EB;
}

.button {
    flex: 1;
    padding: 0.625rem 1.25rem;
    border-radius: 0.5rem;
    font-weight: 500;
    font-size: 0.875rem;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border: none;
    outline: none;
}

.button-approve {
    background-color: #10B981;
    color: white;
}

.button-approve:hover {
    background-color: #059669;
}

.button-reject {
    background-color: #EF4444;
    color: white;
}

.button-reject:hover {
    background-color: #DC2626;
}

.button-cancel {
    background-color: #F3F4F6;
    color: #374151;
    border: 1px solid #D1D5DB;
}

.button-cancel:hover {
    background-color: #E5E7EB;
}

/* Modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    z-index: 50;
    backdrop-filter: blur(4px);
}

.modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
    max-width: 32rem;
    width: 100%;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.modal-header {
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 1rem;
}

.modal-body {
    margin-bottom: 1.5rem;
}

.modal-textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #D1D5DB;
    border-radius: 0.5rem;
    margin: 0.5rem 0;
    font-size: 0.875rem;
    resize: vertical;
}

.modal-textarea:focus {
    outline: none;
    border-color: #3B82F6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
}

.button-cancel {
    background-color: #F3F4F6;
    color: #374151;
    border: 1px solid #D1D5DB;
}

.button-cancel:hover {
    background-color: #E5E7EB;
}

/* Responsive Design */
@media (max-width: 768px) {
    .billing-grid {
        grid-template-columns: 1fr;
    }

    .modal-content {
        margin: 1rem;
        padding: 1.5rem;
    }

    .creator-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }
}

/* Typography */
h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 1.5rem;
}

h3 {
    font-size: 1.125rem;
    font-weight: 600;
    color: #1f2937;
    margin: 0;
}

h4 {
    font-size: 0.875rem;
    font-weight: 600;
    color: #4b5563;
    margin: 0 0 0.75rem 0;
}

/* Empty State */
.empty-state {
    text-align: center;
    color: #6b7280;
    padding: 1rem;
}

/* Add these new styles to your existing CSS */

.creator-id {
    color: #6b7280;
    font-size: 0.7rem;
    margin-left: 0.25rem;
}

.conversion-id {
    font-family: monospace;
    font-size: 0.7rem;
    color: #4b5563;
    background: #f3f4f6;
    padding: 0.125rem 0.25rem;
    border-radius: 3px;
}

.detail-item span {
    font-size: 0.7rem;
    color: #6b7280;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

/* Update existing detail-group for better spacing */
.detail-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
}

/* Make the payment details more compact */
.payment-details {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
}

/* Add these new styles for the offer name */
.offer-details {
    padding: 0.5rem 0;
    border-bottom: 1px solid #e5e7eb;
    margin-bottom: 0.5rem;
}

.offer-name {
    font-size: 0.8rem;
    color: #1f2937;
    font-weight: 500;
    display: block;
    line-height: 1.2;
}

/* Update conversion ID styling */
.conversion-item {
    grid-column: 1 / -1;
    margin-top: 0.25rem;
}

.conversion-id {
    font-family: monospace;
    font-size: 0.7rem;
    color: #4b5563;
    background: #f3f4f6;
    padding: 0.125rem 0.25rem;
    border-radius: 3px;
    word-break: break-all;
} 
.brand-work-container {
    padding: 2rem;
    height: calc(100vh - 64px);
    background-color: #f8f9fa;
}

.brand-work-layout {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 2rem;
    height: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

/* Partnerships List Styles */
.partnerships-section {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.partnerships-header {
    padding: 1.25rem;
    border-bottom: 1px solid #eee;
}

.partnerships-header h2 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
    color: #2d3748;
}

.partnership-count {
    font-size: 0.875rem;
    color: #718096;
    margin-top: 0.25rem;
    display: block;
}

.partnerships-list {
    overflow-y: auto;
    flex: 1;
}

/* Partnership Item Styles */
.partnership-item {
    padding: 1rem;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.15s ease;
}

.partnership-item:hover {
    background-color: #f8fafc;
}

.partnership-item.selected {
    background-color: #ebf4ff;
}

.partnership-title-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.partnership-title-row h3 {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
    color: #2d3748;
}

.partnership-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.offer-title {
    font-size: 0.875rem;
    color: #4a5568;
    margin: 0;
    font-weight: 500;
}

.partnership-date {
    font-size: 0.75rem;
    color: #718096;
    margin: 0.25rem 0 0 0;
}

/* Partnership Details Styles */
.partnership-details {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    padding: 2rem;
    height: 100%;
    overflow-y: auto;
}

.brand-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0;
}

.brand-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #2d3748;
}

.offer-subtitle {
    margin: 0;
    color: #4a5568;
    font-size: 1rem;
}

.partnership-info-grid {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

.info-card {
    background: #f8fafc;
    padding: 1.25rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
}

.info-card.full-width {
    grid-column: 1 / -1;
}

.info-card h4 {
    margin: 0 0 0.75rem 0;
    font-size: 0.875rem;
    color: #4a5568;
    font-weight: 600;
}

.info-card p {
    margin: 0.25rem 0;
    font-size: 0.875rem;
    color: #718096;
}

.main-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
}

.left-section {
    flex: 1;
}

.offer-details-card {
    background: #f8fafc;
    padding: 1.25rem;
    border-radius: 8px;
    min-width: 250px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
}

.offer-details-card h4 {
    margin: 0 0 1rem 0;
    font-size: 0.875rem;
    color: #4a5568;
    font-weight: 600;
}

.offer-details-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.offer-details-content p {
    margin: 0;
    font-size: 0.875rem;
    color: #64748b;
}

.offer-price {
    font-size: 1rem !important;
    color: #2d3748 !important;
    font-weight: 500;
}

.status-badge {
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
    border-radius: 12px;
    font-weight: 500;
    text-transform: capitalize;
}

.status-badge.pending { background: #fff3cd; color: #856404; }
.status-badge.accepted { background: #d4edda; color: #155724; }
.status-badge.completed { background: #cce5ff; color: #004085; }
.status-badge.rejected { background: #f8d7da; color: #721c24; }
.status-badge.cancelled { background: #e2e3e5; color: #383d41; }

/* Scrollbar styling */
.partnerships-list::-webkit-scrollbar,
.partnership-details::-webkit-scrollbar {
    width: 6px;
}

.partnerships-list::-webkit-scrollbar-track,
.partnership-details::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.partnerships-list::-webkit-scrollbar-thumb,
.partnership-details::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 3px;
}

.partnerships-list::-webkit-scrollbar-thumb:hover,
.partnership-details::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

/* Update these logo styles */
.brand-logo {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    object-fit: contain;
    margin-right: 8px;
}

.brand-logo-large {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    object-fit: contain;
    margin-right: 12px;
}

/* Add these new styles */
.offer-info {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.offer-chosen-badge {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    background-color: #e0f2fe;
    color: #0369a1;
    border-radius: 4px;
    font-weight: 500;
}

.offer-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin: 0;
}

.offer-header h3 {
    margin: 0;
    font-size: 1.1rem;
    color: #4a5568;
    font-weight: 500;
}

.offer-chosen-tag {
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
    background-color: #e0f2fe;
    color: #0369a1;
    border-radius: 4px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
}

.partnership-title-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.project-status {
    margin: 0.5rem 0;
}

/* Remove any other offer-chosen related styles */ 

/* Unique classes for the work offer section */
.work-offer-header {
    padding: 12px;
    background: #fff;
    border-radius: 6px;
}

.work-offer-title {
    font-size: 0.875rem;
    color: #4a5568;
    margin: 0 0 8px 0;
    font-weight: 600;
}

.work-offer-row {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.work-offer-label {
    font-size: 0.875rem;
    color: #64748b;
    min-width: 100px;
    text-align: left;
}

.work-offer-value {
    font-size: 0.875rem;
    color: #1a202c;
    font-weight: 500;
}

/* Add these new styles */
.order-item {
    transition: all 0.2s ease;
    border-bottom: 1px solid #e2e8f0;
}

.order-item:hover {
    background-color: #f7fafc;
    transform: translateY(-1px);
}

.order-item.selected {
    background-color: #ebf8ff;
    border-left: 3px solid #3182ce;
}

.order-item:active {
    transform: translateY(0);
}

.order-avatar {
    border: 2px solid #e2e8f0;
    transition: border-color 0.2s ease;
}

.order-item:hover .order-avatar {
    border-color: #3182ce;
}

.order-content {
    transition: background-color 0.2s ease;
}

.order-item:hover .order-content {
    background-color: #f7fafc;
}

/* Update scrollbar styling */
.vstack-scroll::-webkit-scrollbar {
    width: 6px;
}

.vstack-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
}

.vstack-scroll::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 3px;
}

.vstack-scroll::-webkit-scrollbar-thumb:hover {
    background: #a0aec0;
}

.chakra-tabs__tablist {
    border-bottom: 2px solid #E2E8F0 !important;
    margin-bottom: 0 !important;
}

.chakra-tabs__tab {
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: #4A5568 !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    position: relative !important;
    transition: all 0.2s !important;
}

.chakra-tabs__tab[aria-selected="true"] {
    color: #2B6CB0 !important;
    font-weight: 600 !important;
    background: transparent !important;
    border-bottom: 3px solid #2B6CB0 !important;
    margin-bottom: -2px !important;
}

.chakra-tabs__tab:hover {
    color: #3182CE !important;
    background-color: #F7FAFC !important;
}

.chakra-tabs__tab:active {
    background-color: #EDF2F7 !important;
} 
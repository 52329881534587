.tracking-stats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin: 20px 0;
}

.stat-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
}

.stat-card h3 {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.stat-card p {
  margin: 10px 0 0;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.stats-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  padding: 0;
  max-width: 900px;
  margin: 0 auto;
}

.stat-card {
  position: relative;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  text-align: left;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stat-card:hover {
  border-color: #cbd5e1;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transform: translateY(-1px);
}

.stat-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  border-radius: 10px 10px 0 0;
}

/* Top color bars for each card */
.stat-card:nth-child(1)::before { background: #3b82f6; }  /* Blue */
.stat-card:nth-child(2)::before { background: #10b981; }  /* Green */
.stat-card:nth-child(3)::before { background: #f59e0b; }  /* Orange */
.stat-card:nth-child(4)::before { background: #8b5cf6; }  /* Purple */
.stat-card:nth-child(5)::before { background: #6b7280; }  /* Gray */

.stat-card h3 {
  font-size: 0.7rem;
  color: #64748b;
  margin: 0;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  margin-bottom: 8px;
}

.stat-card p {
  font-size: 1.1rem;
  color: #0f172a;
  margin: 0;
  font-weight: 600;
  line-height: 1.2;
}

/* For smaller screens */
@media (max-width: 768px) {
  .stats-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  .stat-card:last-child {
    grid-column: span 2;
  }

  .stat-card {
    padding: 14px;
  }

  .stat-card p {
    font-size: 1rem;
  }
}

/* Loading and error states */
.stats-loading, .stats-error {
  text-align: center;
  padding: 12px;
  color: #64748b;
  font-size: 0.75rem;
}

.stats-error {
  color: #ef4444;
} 
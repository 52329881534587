.brand-profile-container {
    display: flex;
    gap: 24px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px;
}

.profile-navigation {
    width: 240px;
    flex-shrink: 0;
}

.nav-section {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.nav-item {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 16px;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    font-size: 0.95rem;
    color: #4a5568;
    border-left: 3px solid transparent;
    transition: all 0.2s ease;
}

.nav-item:hover {
    background: #f7fafc;
}

.nav-item.active {
    background: #f7fafc;
    color: #2c5282;
    border-left-color: #2c5282;
    font-weight: 500;
}

.nav-icon {
    font-size: 1.2rem;
}

.brand-profile-content {
    flex: 1;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 24px;
}

/* Update existing styles to work with new layout */
.profile-details {
    max-width: none;
    margin: 0;
}

@media (max-width: 768px) {
    .brand-profile-container {
        flex-direction: column;
    }

    .profile-navigation {
        width: 100%;
    }
}

/* Profile View Styles */
.profile-header {
    padding: 24px;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-brand-info {
    display: flex;
    align-items: center;
    gap: 20px;
}

.brand-logo {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 4px;
}

.brand-text h2 {
    margin: 0 0 4px 0;
    font-size: 24px;
    font-weight: 600;
    color: #111827;
}

.website-link {
    margin: 0;
    font-size: 14px;
    color: #6b7280;
}

.website-link a {
    color: #4f46e5;
    text-decoration: none;
}

.website-link a:hover {
    text-decoration: underline;
}

.profile-body {
    padding: 24px;
}

.profile-section {
    margin-bottom: 32px;
}

.profile-section:last-child {
    margin-bottom: 0;
}

.profile-section h3 {
    font-size: 16px;
    font-weight: 600;
    color: #111827;
    margin: 0 0 16px 0;
}

.contact-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 24px;
}

.detail-group {
    margin-bottom: 24px;
}

.detail-group.description {
    margin-bottom: 32px;
}

.detail-group label {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    color: #6b7280;
    margin-bottom: 4px;
    letter-spacing: 0.05em;
}

.detail-group p {
    margin: 0;
    color: #111827;
    font-size: 14px;
    line-height: 1.5;
}

/* Form Styles */
.form-header {
    padding: 24px;
    border-bottom: 1px solid #e5e7eb;
}

.form-logo-section {
    display: flex;
    align-items: center;
    gap: 20px;
}

.brand-name-input {
    font-size: 24px;
    font-weight: 600;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    padding: 8px 12px;
    width: 300px;
}

.form-body {
    padding: 24px;
}

.form-section {
    margin-bottom: 32px;
}

.form-section h3 {
    font-size: 16px;
    font-weight: 600;
    color: #111827;
    margin: 0 0 16px 0;
}

.form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 24px;
    margin-bottom: 24px;
}

.form-group {
    margin-bottom: 24px;
}

.form-group label {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    color: #6b7280;
    margin-bottom: 4px;
    letter-spacing: 0.05em;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
}

.form-group input:disabled {
    background-color: #f9fafb;
    cursor: not-allowed;
}

.form-group textarea {
    min-height: 100px;
    resize: vertical;
}

.form-actions {
    padding: 24px;
    border-top: 1px solid #e5e7eb;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.edit-button,
.save-button,
.cancel-button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.edit-button,
.save-button {
    background-color: #4f46e5;
    color: white;
    border: none;
}

.edit-button:hover,
.save-button:hover {
    background-color: #4338ca;
}

.cancel-button {
    background-color: white;
    border: 1px solid #e5e7eb;
    color: #6b7280;
}

.cancel-button:hover {
    background-color: #f9fafb;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    color: #6b7280;
}

.signup-choice-container {
    max-width: 900px;
    margin: 80px auto 0;
    padding: 40px 20px;
    text-align: center;
}

.signup-choice-title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #000;
    text-align: center;
}

.signup-choice-subtitle {
    font-size: 18px;
    color: #6b7280;
    margin-bottom: 2rem;
    text-align: center;
}

.choice-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-top: 40px;
}

.choice-card {
    background: white;
    border: 1px solid #eee;
    border-radius: 12px;
    padding: 40px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.choice-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.choice-card-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #1a1a1a;
    text-align: center;
}

.choice-card p {
    font-size: 16px;
    color: #666;
    margin-bottom: 24px;
}

.choice-btn {
    padding: 12px 24px;
    background: #000;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s ease;
}

.choice-btn:hover {
    background: #333;
}

@media (max-width: 768px) {
    .choice-cards {
        grid-template-columns: 1fr;
    }
    
    .signup-choice-container {
        padding: 20px;
    }
} 
/* General Layout */
.campaign-form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.campaign-form-container h2 {
  text-align: left;
  margin-bottom: 20px;
  color: #333;
}

.campaign-form {
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-width: 700px;
}

.form-section {
  margin-bottom: 30px;
}

.form-section h3 {
  text-align: left;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
}

.form-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 100px;
  font-size: 16px;
}

.submit-button {
  width: 100%;
  padding: 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #45a049;
}

.date-inputs {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.date-inputs .form-input {
  margin-bottom: 0;
}

.commission-container {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.commission-type {
  flex: 2;
}

.commission-amount {
  flex: 1;
}

.commission-symbol {
  position: absolute;
  right: 15px;
  color: #666;
}

.form-sections-nav {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  border-right: 1px solid #eee;
}

.section-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding: 12px 20px;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: right;
  color: #666;
}

.section-button.active {
  background: #f8f9fa;
  color: #000;
  border-radius: 8px;
}

.section-button:hover {
  background: #f8f9fa;
}

.section-button span {
  font-size: 20px;
  order: 2;
}

.section-button .section-title {
  order: 1;
  font-weight: 500;
}

.campaign-form-nav-sidebar {
  width: 280px;
  border-right: 1px solid #eee;
  padding: 20px 0;
}

.campaign-form-nav-menu {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.campaign-form-nav-item {
  padding: 12px 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 6px;
  margin: 0 10px;
}

.campaign-form-nav-item:hover {
  background: #f0f2f5;
}

.campaign-form-nav-item.active {
  background: #e7f0ff;
  color: #1a73e8;
}

.campaign-form-nav-item.active:hover {
  background: #d9e7ff;
}

.campaign-form-nav-item-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

.campaign-form-nav-item-title {
  font-size: 15px;
  color: inherit;
}

.campaign-form-nav-item-icon {
  font-size: 20px;
}

.progress-bar {
  height: 4px;
  background: #eee;
  margin-bottom: 2rem;
  border-radius: 2px;
}

.progress-fill {
  height: 100%;
  background: #0066ff;
  border-radius: 2px;
  transition: width 0.3s ease;
}

.form-step {
  margin-bottom: 2rem;
}

.step-header {
  margin-bottom: 2rem;
}

.step-count {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
  display: block;
}

.step-header h2 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.step-header p {
  color: #666;
}

.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.helper-text {
  font-size: 0.85rem;
  color: #666;
}

.form-navigation {
  display: flex;
  justify-content: space-between;

  
  border-top: 1px solid #eee;
}

.back-button,
.next-button,
.submit-button {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.back-button {
  background: none;
  border: 1px solid #ddd;
  color:black
}

.next-button,
.submit-button {
  background: #0066ff;
  color: white;
  border: none;
}

.next-button:hover,
.submit-button:hover {
  background: #0052cc;
}

.back-button:hover {
  background: #f5f5f5;
}

.platform-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
}

.platform-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.platform-checkbox input[type="checkbox"] {
  margin-right: 12px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.platform-label {
  font-size: 12px;
}

.validation-error {
  background-color: #fff2f0;
  color: #cf1322;
  padding: 16px;
  border-radius: 6px;
  margin-bottom: 24px;
  text-align: center;
  font-weight: 500;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #ffccc7;
  font-size: 16px;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.validation-error::before {
  content: "⚠️";
  font-size: 18px;
}

.save-message {
  padding: 16px;
  border-radius: 6px;
  margin-bottom: 24px;
  text-align: center;
  font-weight: 500;
}

.save-message.success {
  background-color: #f6ffed;
  color: #52c41a;
  border: 1px solid #b7eb8f;
}

.save-message.error {
  background-color: #fff2f0;
  color: #cf1322;
  border: 1px solid #ffccc7;
}

.grid-layout {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-row {
  display: flex;
  gap: 1rem;
}

.half-width {
  flex: 1;
  min-width: 0;
}

.form-group textarea {
  min-height: 60px;
  resize: vertical;
}

.form-step {
  max-width: 800px;
  margin: 0 auto;
}

.step-header {
  margin-bottom: 1.5rem;
}

.form-fields {
  padding: 1.5rem 0;
}

/* Page Layout */
.page-layout {
    display: flex;
    min-height: 100vh;
    background: #f8fafc;
}

/* Navigation Sidebar */
.nav-sidebar {
    width: 280px;
    background: white;
    border-right: 1px solid #e5e7eb;
    padding: 32px 16px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    overflow-y: auto;
    z-index: 10;
}

/* Main Content Area */
.brand-edit-form-container {
    
    padding: 2rem;
    width: calc(100% - 280px);
    min-height: 100vh;
}

.brand-form-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    padding: 2rem;
}

/* Navigation Menu */
.nav-menu {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.nav-item {
    padding: 12px 16px;
    border-radius: 8px;
    color: #4b5563;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 12px;
}

.nav-item:hover {
    background: #f8fafc;
    color: #111827;
}

.nav-item.active {
    background: #f8fafc;
    color: #111827;
    font-weight: 600;
    border-left: 3px solid #000;
}

.nav-item span {
    font-size: 1.25rem;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
    .nav-sidebar {
        width: 240px;
    }
    
    .brand-edit-form-container {
        margin-left: 240px;
        width: calc(100% - 240px);
    }
}

@media (max-width: 768px) {
    .nav-sidebar {
        display: none;
    }
    
    .brand-edit-form-container {
        margin-left: 0;
        width: 100%;
    }
}

/* Keep all existing styles below this line */
.brand-edit-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #f0f2f5;
}

.brand-header-left {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.brand-header-left h2 {
    margin: 0;
    color: #1a1a1a;
    font-size: 1.75rem;
}

.brand-back-btn {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 0.95rem;
    color: #4b5563;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.2s ease;
}

.brand-back-btn:hover {
    color: #1a1a1a;
}

.brand-form-section {
    background: #fff;
    padding: 1.5rem;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    height: fit-content;
    animation: fadeIn 0.3s ease-out;
}

.brand-section-title {
    color: #374151;
    font-size: 1.25rem;
    margin: 0 0 1.5rem 0;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #e5e7eb;
}

.brand-form-group {
    margin-bottom: 1.5rem;
}

.brand-form-group:last-child {
    margin-bottom: 0;
}

.brand-form-group label {
    display: block;
    margin-bottom: 12px;
    font-weight: 500;
    color: #374151;
}

.brand-form-group input,
.brand-form-group select,
.brand-form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    font-size: 1rem;
    transition: border-color 0.2s ease;
    background-color: #fff;
}

.brand-form-group input:focus,
.brand-form-group select:focus,
.brand-form-group textarea:focus {
    outline: none;
    border-color: #6366f1;
    box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
}

.brand-form-group textarea {
    min-height: 100px;
    resize: vertical;
}

.brand-form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.brand-form-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    padding-top: 1.5rem;
    border-top: 2px solid #f0f2f5;
}

.brand-form-actions-right {
    display: flex;
    gap: 1rem;
}

.brand-cancel-btn,
.brand-save-btn {
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.brand-cancel-btn {
    background: white;
    border: 1px solid #d1d5db;
    color: #4b5563;
}

.brand-cancel-btn:hover {
    background: #f9fafb;
    border-color: #9ca3af;
}

.brand-save-btn {
    background: #6366f1;
    border: none;
    color: white;
}

.brand-save-btn:hover {
    background: #4f46e5;
}

/* Optional section divider */
.brand-form-section + .brand-form-section {
    margin-top: 2rem;
}

/* Placeholder styling */
.brand-form-group input::placeholder,
.brand-form-group textarea::placeholder {
    color: #9ca3af;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .brand-form-columns {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .brand-edit-form-container {
        max-width: 900px;
        margin: 1rem;
        padding: 1.5rem;
    }
}

/* Helper text styling */
.brand-form-helper {
    font-size: 0.875rem;
    color: #6b7280;
    margin-top: 0.25rem;
}

/* Section spacing */
.brand-form-section:not(:last-child) {
    margin-bottom: 2rem;
}

.brand-loading {
    text-align: center;
    padding: 2rem;
    color: #4a5568;
}

/* Form validation styles */
.brand-form-group input:invalid,
.brand-form-group select:invalid,
.brand-form-group textarea:invalid {
    border-color: #fc8181;
}

.brand-save-message {
    margin-top: 1rem;
    padding: 0.75rem 1rem;
    border-radius: 6px;
    font-size: 0.875rem;
    animation: fadeIn 0.3s ease-in;
}

.brand-save-message.success {
    background-color: #def7ec;
    color: #03543f;
    border: 1px solid #bcf0da;
}

.brand-save-message.error {
    background-color: #fde8e8;
    color: #9b1c1c;
    border: 1px solid #f8b4b4;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
} 

/* Add these new styles for the columns layout */
.brand-form-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-bottom: 2rem;
}

.brand-form-column {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.brand-delete-btn {
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: #fff;
    border: 1px solid #dc2626;
    color: #dc2626;
}

.brand-delete-btn:hover {
    background-color: #dc2626;
    color: white;
}

.brand-delete-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.brand-image-preview {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
}

.image-preview-item {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.image-preview-item img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
}

.remove-image-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 0.75rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.remove-image-btn:hover {
    background: rgba(0, 0, 0, 0.8);
}

input[type="file"] {
    width: 100%;
    padding: 0.75rem;
    border: 1px dashed #d1d5db;
    border-radius: 6px;
    cursor: pointer;
}

input[type="file"]:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.uploaded-images-container {
    margin-top: 1rem;
}

.uploaded-images-group {
    margin-bottom: 2rem;
}

.uploaded-images-group h4 {
    margin-bottom: 1rem;
    color: #374151;
    font-size: 1rem;
}

.uploaded-images-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
}

.uploaded-image-item {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    aspect-ratio: 1;
}

.uploaded-image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.remove-image-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.2s;
}

.remove-image-btn:hover {
    background: rgba(0, 0, 0, 0.8);
}

.upload-section {
    margin-bottom: 2rem;
}

.upload-section h3 {
    margin-bottom: 1rem;
    color: #374151;
    font-size: 1rem;
    font-weight: 600;
}

.file-input {
    width: 100%;
    padding: 0.75rem;
    border: 2px dashed #e5e7eb;
    border-radius: 6px;
    cursor: pointer;
    transition: border-color 0.2s;
}

.file-input:hover {
    border-color: #6366f1;
}

.preview-section {
    margin-bottom: 2rem;
}

.preview-section h3 {
    margin-bottom: 1rem;
    color: #374151;
    font-size: 1rem;
    font-weight: 600;
}

.upload-status {
    margin-top: 1rem;
    padding: 0.5rem;
    background-color: #f3f4f6;
    border-radius: 4px;
    text-align: center;
    color: #4b5563;
}

/* Progress indicator */
.form-progress {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.progress-step {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #e5e7eb;
    color: #6b7280;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    margin-right: 8px;
}

.progress-step.completed {
    background: #000;
    color: white;
}

.progress-step.active {
    background: #000;
    color: white;
    box-shadow: 0 0 0 3px rgba(0,0,0,0.1);
}

.brand-form-section textarea {
    width: 100%;
    min-height: 120px;
    padding: 12px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    resize: vertical;
    margin-top: 8px;
}

.brand-form-section textarea::placeholder {
    color: #9ca3af;
    font-size: 14px;
}

.brand-form-section textarea:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

.platform-checkboxes {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
    margin-top: 12px;
    margin-bottom: 24px;
}

.platform-checkbox {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 14px;
}

.platform-checkbox:hover {
    background: #f3f4f6;
}

.platform-checkbox input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin: 0;
}

.platform-checkbox span {
    padding-left: 4px;
}

.platform-checkbox input[type="checkbox"]:checked + span {
    color: #2563eb;
}

.character-count {
    display: block;
    text-align: right;
    color: #666;
    font-size: 0.8rem;
    margin-top: 4px;
}
  
.creator-dashboard {
    padding: 20px;
    min-height: 100vh;
    background: #f5f5f5;
}

.dashboard-layout {
    display: flex;
    gap: 24px;
    max-width: 1400px;
    margin: 0 auto;
}

.dashboard-sidebar {
    width: 250px;
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    height: fit-content;
    position: sticky;
    top: 20px;
}

.sidebar-header {
    text-align: center;
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #eee;
}

.sidebar-header h3 {
    margin-top: 12px;
    color: #333;
}

.sidebar-nav {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.nav-item {
    padding: 12px 16px;
    border: none;
    background: none;
    text-align: left;
    font-size: 1rem;
    color: #666;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.nav-item:hover {
   
    color: #333;
}

.nav-item.active {
    background: #007bff;
    color: white;
}

.dashboard-main {
    flex: 1;
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.dashboard-header {
    margin-bottom: 24px;
}

.dashboard-header h1 {
    color: #333;
    font-size: 1.8rem;
    margin: 0;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.header-left {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.dashboard-header .profile-image-wrapper {
    width: 60px;
    height: 60px;
}

.header-text h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.create-offer-button {
    padding: 12px 24px;
    background: #000;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s ease;
}

.create-offer-button:hover {
    background: #333;
    transform: translateY(-1px);
}

.stats-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.stats-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.stat-card {
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-align: center;
    transition: transform 0.2s ease;
}

.stat-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.stat-card h3 {
    margin: 0 0 12px 0;
    color: #666;
    font-size: 1rem;
    font-weight: 500;
}

.stat-card p {
    margin: 0;
    color: #333;
    font-size: 1.8rem;
    font-weight: 600;
}

/* Make it responsive */
@media (max-width: 768px) {
    .stats-row {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .stats-row {
        grid-template-columns: 1fr;
    }
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

.dashboard-section {
    background: white;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.dashboard-section h2 {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 2px solid #f0f0f0;
}

.campaigns-list,
.offers-list {
    display: grid;
    gap: 20px;
}

.campaign-card,
.offer-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    transition: all 0.2s ease;
}

.campaign-card:hover,
.offer-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
}

.campaign-card h3,
.offer-card h3 {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 12px;
}

.campaign-brand,
.campaign-status,
.campaign-earnings,
.offer-price,
.offer-status {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
}

.view-details-button {
    display: inline-block;
    padding: 8px 16px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 6px;
    color: #000;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
}

.view-details-button:hover {
    background: #f0f0f0;
    border-color: #ccc;
}

.no-data {
    color: #666;
    text-align: center;
    padding: 20px;
}

@media (max-width: 1024px) {
    .stats-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .dashboard-grid {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .stats-grid {
        flex-wrap: nowrap;
        margin: 0 -1rem;
        padding: 0.75rem 1rem;
    }
}

.offers-section {
    padding: 24px;
}

.offers-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.create-offer-button {
    padding: 12px 24px;
    background: #000;
    color: white;
    border: none;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.2s ease;
}

.create-offer-button:hover {
    background: #333;
    transform: translateY(-1px);
}

.offers-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    padding: 20px;
}

.offer-card {
    background: white;
    border-radius: 12px;
    padding: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    border: 1px solid #eef2f6;
    position: relative;
    overflow: hidden;
}

.offer-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    border-color: #e2e8f0;
}

.offer-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #4f46e5, #7c3aed);
}

.offer-type {
    color: #4f46e5;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 12px;
}

.offer-card h3 {
    margin: 0 0 0px 0;
    color: #1a202c;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.2;
}

.offer-price {
    font-size: 2rem;
    font-weight: 700;
    color: #2d3748;
    margin: 16px 0;
    display: flex;
    align-items: baseline;
}

.offer-price::before {
    content: '$';
    font-size: 1.25rem;
    margin-right: 4px;
    color: #4a5568;
}

.offer-delivery {
    color: #4a5568;
    font-size: 0.9rem;
    margin: 12px 0;
    padding: 8px 0;
    border-top: 1px solid #edf2f7;
    border-bottom: 1px solid #edf2f7;
}

.offer-includes {
    margin-top: 20px;
}

.offer-includes strong {
    display: block;
    margin-bottom: 12px;
    color: #2d3748;
    font-size: 1rem;
    font-weight: 600;
}

.offer-includes ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.offer-includes li {
    padding: 8px 0;
    color: #4a5568;
    position: relative;
    padding-left: 24px;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
}

.offer-includes li:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: #4f46e5;
    font-weight: bold;
}

.offers-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    padding: 24px;
}

/* Add hover effect for list items */
.offer-includes li:hover {
    color: #2d3748;
    transform: translateX(2px);
    transition: all 0.2s ease;
}

/* Add subtle animation for price */
.offer-price {
    transition: color 0.3s ease;
}

.offer-card:hover .offer-price {
    color: #4f46e5;
}

.no-offers {
    text-align: center;
    padding: 48px;
    background: #f8f9fa;
    border-radius: 12px;
    color: #666;
}

.no-offers p:first-child {
    font-size: 18px;
    margin-bottom: 12px;
}

.loading {
    text-align: center;
    padding: 48px;
    color: #666;
}

.offer-name {
    font-size: 1.3rem;
    color: #333;
    margin: 0 0 5px 0;
    font-weight: 600;
}

.brand-name {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
    font-weight: 500;
}

.campaign-card {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    gap: 12px;
    transition: transform 0.2s ease;
}

.campaign-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Campaigns Grid */
.creator-campaigns-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding: 24px;
    max-width: 100%;
}

.campaign-card {
    width: 100%;
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.campaign-card .offer-name {
    font-size: 18px;
    font-weight: 600;
    color: #111827;
    margin: 0;
}

.campaign-card .brand-name {
    font-size: 14px;
    color: #6b7280;
    margin-top: -8px;
}

.campaign-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.campaign-payout {
    font-size: 16px;
    font-weight: 600;
    color: #4f46e5;
    padding: 8px 12px;
    background: #eef2ff;
    border-radius: 6px;
    display: inline-block;
}

.campaign-metrics {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    background: #f9fafb;
    padding: 16px;
    border-radius: 8px;
}

.metric {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.metric span {
    font-size: 13px;
    color: #6b7280;
}

.metric:last-child {
    font-weight: 500;
    color: #111827;
}

.affiliate-link {
    background: #f9fafb;
    padding: 16px;
    border-radius: 8px;
}

.affiliate-link p {
    font-size: 14px;
    color: #6b7280;
    margin: 0 0 8px 0;
}

.link-container {
    display: flex;
    gap: 12px;
    align-items: center;
}

.link-container code {
    flex: 1;
    padding: 8px 12px;
    background: white;
    border-radius: 6px;
    font-size: 13px;
    color: #4f46e5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.copy-button {
    padding: 8px 16px;
    background: #4f46e5;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.copy-button:hover {
    background: #4338ca;
}

.view-details-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    color: #4f46e5;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s;
}

.view-details-button:hover {
    background: #f9fafb;
    border-color: #4f46e5;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .creator-campaigns-grid {
        grid-template-columns: 1fr;
        padding: 16px;
    }

    .campaign-metrics {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .creator-campaigns-grid {
        grid-template-columns: 1fr;
        padding: 16px;
    }
}

.campaign-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.archive-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.archive-button:hover {
    background-color: #c82333;
}

.archive-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.25);
}
.user-management {
    padding: 20px;
    width: 100%;
}

.user-management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.table-controls {
    margin-bottom: 20px;
}

.search-input {
    padding: 8px 12px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    width: 300px;
}

.users-table {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.table-header {
    display: grid;
    grid-template-columns: minmax(200px, 1.5fr) minmax(150px, 1fr) minmax(100px, 0.7fr) minmax(100px, 0.7fr) minmax(100px, 0.7fr) 80px;
    background: #f7fafc;
    padding: 12px;
    font-weight: 600;
    border-bottom: 1px solid #e2e8f0;
    gap: 8px;
}

.table-body {
    padding: 8px 0;
}

.table-row {
    display: grid;
    grid-template-columns: minmax(200px, 1.5fr) minmax(150px, 1fr) minmax(100px, 0.7fr) minmax(100px, 0.7fr) minmax(100px, 0.7fr) 80px;
    padding: 12px;
    border-bottom: 1px solid #e2e8f0;
    align-items: center;
    gap: 8px;
}

.table-row:last-child {
    border-bottom: none;
}

.table-cell {
    padding: 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.user-info {
    display: flex;
    align-items: center;
    gap: 12px;
    min-width: 0; /* Allow flex item to shrink */
}

.user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0; /* Prevent avatar from shrinking */
}

.user-details {
    display: flex;
    flex-direction: column;
    min-width: 0; /* Allow content to shrink */
}

.user-displayname {
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.user-email {
    font-size: 0.875rem;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.user-username {
    color: #666;
    font-size: 0.9em;
    margin-top: 2px;
}

.role-badge {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 500;
}

.role-badge.admin {
    background: #ebf8ff;
    color: #2b6cb0;
}

.role-badge.brand {
    background: #e9d8fd;
    color: #553c9a;
}

.role-badge.affiliate {
    background: #fefcbf;
    color: #975a16;
}

.role-badge.user {
    background: #e2e8f0;
    color: #4a5568;
}

.header-cell {
    padding: 0 12px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.header-cell.clickable {
    cursor: pointer;
    user-select: none;
}

.header-cell.clickable:hover {
    color: #4299e1;
}

.status-badge {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.875rem;
    background: #E6F4EA;
    color: #1E7E34;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.status-badge.inactive {
    background: #FEE2E2;
    color: #DC2626;
}

.status-badge.suspended {
    background: #FEF3C7;
    color: #B45309;
}

.loading, .error {
    padding: 24px;
    text-align: center;
    color: #4a5568;
}

.error {
    color: #e53e3e;
}

.clickable {
    cursor: pointer;
    user-select: none;
}

.clickable:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

@media (max-width: 768px) {
    .user-management {
        padding: 16px;
    }

    .user-management-header {
        flex-direction: column;
        gap: 16px;
    }

    .search-input {
        width: 100%;
    }

    .table-header, .table-row {
        grid-template-columns: 1fr;
        gap: 8px;
    }

    .header-cell:not(:first-child) {
        display: none;
    }

    .table-cell {
        padding: 4px 0;
    }

    .table-cell:not(:first-child) {
        font-size: 0.8rem;
        color: #718096;
    }
} 
/* Main Layout */
.billing-section {
    padding: 24px;
    max-width: 1400px;
    margin: 0 auto;
}

.billing-header {
    margin-bottom: 32px;
}

.billing-header h2 {
    font-size: 28px;
    color: #1a1a1a;
    margin-bottom: 16px;
}

/* Tabs */
.section-tabs {
    display: flex;
    gap: 12px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 24px;
}

.tab {
    padding: 12px 24px;
    border: none;
    background: none;
    font-size: 16px;
    color: #666;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
}

.tab.active {
    color: #2563eb;
    font-weight: 500;
}

.tab.active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background: #2563eb;
}

/* Summary Cards */
.billing-overview {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.summary-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    margin-bottom: 32px;
}

.summary-card {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    transition: transform 0.2s ease;
}

.summary-card:hover {
    transform: translateY(-2px);
}

.summary-card.primary {
    background: #2563eb;
    color: white;
}

.summary-card.primary h4 {
    color: rgba(255,255,255,0.9);
}

.summary-details h4 {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
}

.summary-number {
    font-size: 28px;
    font-weight: 600;
    color: #1a1a1a;
}

.summary-card.primary .summary-number {
    color: white;
}

/* Program Performance */
.program-performance {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    margin-bottom: 32px;
}

.program-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 16px;
    margin-top: 16px;
}

.program-card {
    background: #f8fafc;
    border-radius: 8px;
    padding: 16px;
    border: 1px solid #e5e7eb;
}

.program-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.program-header h5 {
    font-size: 15px;
    color: #1a1a1a;
    margin: 0;
}

.program-total {
    font-weight: 600;
    color: #2563eb;
}

.program-stats {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #666;
}

/* Activity Table */
.recent-activity {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    margin-bottom: 32px;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.activity-table {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th {
    text-align: left;
    padding: 12px;
    font-size: 13px;
    font-weight: 500;
    color: #666;
    border-bottom: 1px solid #e5e7eb;
}

td {
    padding: 12px;
    font-size: 14px;
    border-bottom: 1px solid #e5e7eb;
}

.status-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
}

.status-badge.pending {
    background: #fff7ed;
    color: #9a3412;
}

.status-badge.paid {
    background: #f0fdf4;
    color: #166534;
}

.status-badge.processing {
    background: #eff6ff;
    color: #1e40af;
}

.status-badge.approved {
    background-color: #10B981;
    color: white;
}

.status-badge.rejected {
    background-color: #EF4444;
    color: white;
}

.status-badge.pending {
    background-color: #F59E0B;
    color: white;
}

.rejection-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.tooltip {
    position: relative;
    cursor: help;
    color: #6B7280;
}

.tooltip-text {
    visibility: hidden;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #1F2937;
    color: white;
    padding: 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    white-space: nowrap;
    z-index: 10;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}

/* Payment Methods Section */
.payment-methods-section {
    padding: 1.5rem;
    background: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.add-payment-button {
    padding: 0.5rem 1rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.add-payment-button:hover {
    background-color: #1d4ed8;
}

.no-payment-methods {
    text-align: center;
    padding: 2rem;
    background: #f9fafb;
    border-radius: 0.5rem;
}

.add-first-payment-button {
    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.add-first-payment-button:hover {
    background-color: #1d4ed8;
}

.payment-methods-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
}

.payment-method-card {
    padding: 1.5rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    background: white;
    transition: box-shadow 0.2s;
}

.payment-method-card:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.card-header h4 {
    margin: 0;
    color: #111827;
    font-size: 1rem;
    font-weight: 500;
}

.default-badge {
    background-color: #10B981;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
}

.card-body {
    color: #6B7280;
}

.card-body p {
    margin: 0.5rem 0;
    font-size: 0.875rem;
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 500px;
    position: relative;
}

.modal-close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #6B7280;
}

.modal-close-btn:hover {
    color: #111827;
}

/* Payment Form Styles */
.payment-form {
    margin-top: 1rem;
}

.form-row {
    padding: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    margin-bottom: 1rem;
}

.error-message {
    color: #DC2626;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.submit-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.submit-button:disabled {
    background-color: #93C5FD;
    cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
    background-color: #1d4ed8;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .billing-section {
        padding: 16px;
    }

    .summary-grid {
        grid-template-columns: 1fr;
    }

    .program-grid {
        grid-template-columns: 1fr;
    }

    .activity-table {
        margin: 0 -16px;
    }

    table {
        font-size: 13px;
    }

    th, td {
        padding: 8px;
    }
}

/* Loading and Error States */
.loading-state {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    color: #666;
}

.error-state {
    background: #fef2f2;
    color: #991b1b;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 24px;
}

/* Billing Cycle Section */
.billing-cycle-info {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    margin-bottom: 24px;
}

.cycle-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.cycle-header h4 {
    font-size: 16px;
    color: #1a1a1a;
    margin: 0;
}

.cycle-dates {
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 500;
    background: #f8fafc;
    padding: 6px 12px;
    border-radius: 20px;
    border: 1px solid #e5e7eb;
}

.cycle-progress {
    height: 8px;
    position: relative;
    margin: 24px 0;
    border-radius: 4px;
    overflow: visible;
}

.progress-bar-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e5e7eb;
    border-radius: 4px;
}

.progress-bar-fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #2563eb !important;
    border-radius: 4px 0 0 4px;
    transition: width 0.3s ease;
}

/* Make sure the fill shows up */
.progress-bar-fill {
    z-index: 1;
}

.progress-marker {
    position: absolute;
    top: -4px;
    transform: translateX(-50%);
    z-index: 2;
}

.marker-line {
    width: 2px;
    height: 16px;
    background: #1e40af;
    margin: 0 auto;
}

.marker-label {
    font-size: 12px;
    color: #1e40af;
    margin-top: 4px;
    white-space: nowrap;
    text-align: center;
    font-weight: 500;
}

.progress-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 12px;
    color: #666;
}

.cycle-details {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
    margin-bottom: 20px;
}

.cycle-stat {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.cycle-stat .label {
    font-size: 13px;
    color: #666;
}

.cycle-stat .value {
    font-size: 15px;
    font-weight: 500;
    color: #1a1a1a;
}

.cycle-info-box {
    display: flex;
    gap: 16px;
    background: #f8fafc;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
}

.info-icon {
    font-size: 20px;
}

.info-text {
    flex: 1;
}

.info-text p {
    margin: 0;
    font-size: 14px;
    color: #666;
    line-height: 1.5;
}

.info-text p:not(:last-child) {
    margin-bottom: 8px;
}

/* Update existing summary card styles */
.summary-subtext {
    font-size: 13px;
    color: #94a3b8;
    margin-top: 4px;
}

.summary-card.primary .summary-subtext {
    color: rgba(255, 255, 255, 0.8);
}

.creator-info {
    display: flex;
    align-items: center;
    gap: 8px;
}

.creator-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.creator-avatar-small {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
}

.view-details-btn {
    padding: 4px 12px;
    border-radius: 6px;
    background: #f3f4f6;
    border: none;
    color: #374151;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.view-details-btn:hover {
    background: #e5e7eb;
}

.program-header .creator-info {
    flex: 1;
}

.program-header .creator-info h5 {
    margin: 0;
    font-size: 14px;
    color: #1a1a1a;
}

/* Payment Methods Section Styles */
.payment-methods-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.info-box {
    background: #f8fafc;
    border-radius: 12px;
    padding: 24px;
    border: 1px solid #e5e7eb;
}

.info-content h4 {
    margin: 0 0 12px 0;
    color: #1a1a1a;
}

.payment-info-list {
    list-style: none;
    padding: 0;
    margin: 16px 0 0 0;
}

.payment-info-list li {
    padding-left: 24px;
    position: relative;
    margin-bottom: 8px;
    color: #666;
}

.payment-info-list li:before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #2563eb;
}

.empty-payment-methods {
    text-align: center;
    padding: 48px;
    background: white;
    border-radius: 12px;
    border: 2px dashed #e5e7eb;
}

.empty-state {
    max-width: 400px;
    margin: 0 auto;
}

.empty-icon {
    font-size: 48px;
    display: block;
    margin-bottom: 16px;
}

.payment-method-card {
    background: white;
    border-radius: 12px;
    padding: 24px;
    border: 1px solid #e5e7eb;
    display: flex;
    align-items: flex-start;
    gap: 16px;
}

.payment-icon {
    font-size: 24px;
    background: #f8fafc;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.payment-method-details {
    flex: 1;
}

.payment-method-details h4 {
    margin: 0 0 4px 0;
    color: #1a1a1a;
}

.method-info {
    color: #666;
    margin: 0 0 8px 0;
}

.method-status {
    font-size: 13px;
    color: #2563eb;
    margin: 0;
}

.payment-method-actions {
    display: flex;
    gap: 8px;
}

.edit-btn,
.remove-btn {
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 13px;
    cursor: pointer;
    border: none;
}

.edit-btn {
    background: #f8fafc;
    color: #1a1a1a;
}

.remove-btn {
    background: #fef2f2;
    color: #dc2626;
}

.payment-history {
    background: white;
    border-radius: 12px;
    padding: 24px;
    border: 1px solid #e5e7eb;
}

.payment-history h4 {
    margin: 0 0 16px 0;
}

.history-table {
    overflow-x: auto;
}

/* Stripe Payment Form Styles */
.payment-form {
    padding: 20px;
    border-radius: 8px;
    background: white;
}

.form-row {
    margin-bottom: 16px;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background: #f8fafc;
}

/* Stripe Element Container */
.StripeElement {
    padding: 12px;
    background: white;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    transition: box-shadow 0.15s ease;
}

.StripeElement--focus {
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
    border-color: #2563eb;
}

.StripeElement--invalid {
    border-color: #dc2626;
}

/* Submit Button */
.submit-button {
    width: 100%;
    padding: 12px;
    background: #2563eb;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.submit-button:hover {
    background: #1d4ed8;
}

.submit-button:disabled {
    background: #a0aec0;
    cursor: not-allowed;
}

/* Error Message */
.error-message {
    color: #dc2626;
    margin-bottom: 16px;
    font-size: 14px;
    padding: 8px;
    background: #fef2f2;
    border-radius: 4px;
}

/* Modal Styles */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    justify-content: center;
    align-items: center;
}

.modal.open {
    display: flex;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    position: relative;
    max-width: 500px;
    width: 90%;
}

.modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px 10px;
    color: #666;
    line-height: 1;
}

.modal-close-btn:hover {
    color: #333;
}

/* Payment Method Card */
.payment-method-card {
    border: 1px solid #e5e7eb;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    background: white;
    transition: box-shadow 0.2s ease;
}

.payment-method-card:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Card Brand Icon */
.card-brand-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8fafc;
    border-radius: 6px;
}

/* Card Details */
.card-details {
    flex: 1;
}

.card-details h4 {
    margin: 0 0 4px 0;
    font-size: 15px;
    color: #1a1a1a;
}

.card-number {
    color: #666;
    font-size: 14px;
}

.expiry-date {
    font-size: 13px;
    color: #666;
}

/* Card Actions */
.card-actions {
    display: flex;
    gap: 8px;
}

.card-action-btn {
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    border: none;
    transition: all 0.2s ease;
}

.edit-btn {
    background: #f8fafc;
    color: #1a1a1a;
}

.edit-btn:hover {
    background: #e5e7eb;
}

.remove-btn {
    background: #fef2f2;
    color: #dc2626;
}

.remove-btn:hover {
    background: #fee2e2;
}

/* Loading States */
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.loading-spinner {
    width: 24px;
    height: 24px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #2563eb;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Success Message */
.success-message {
    color: #059669;
    background: #f0fdf4;
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.success-message::before {
    content: '✓';
    font-weight: bold;
} 
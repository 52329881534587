.campaigns-section {
    padding: 32px;
    background: #f8fafc;
}

.campaigns-container {
    max-width: 1200px;
    margin: 0 auto;
}

.campaigns-header {
    background: linear-gradient(135deg, #1a365d 0%, #2c5282 100%);
    border-radius: 8px;
    padding: 16px 20px;
    margin-bottom: 24px;
    color: #ffffff;
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
}

.header-left {
    display: flex;
    align-items: center;
    position: relative;
}

.header-text {
    flex: 1;
}

.campaigns-title {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 6px;
    color: #ffffff !important;
    line-height: 1.1;
}

.campaigns-subtitle {
    font-size: 0.9rem;
    line-height: 1.3;
    color: #ffffff !important;
    margin: 0;
}

.campaigns-stats {
    display: flex;
    gap: 20px;
    flex-shrink: 0;
}

.stat-item {
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    padding: 8px 16px;
    border-radius: 6px;
    min-width: 100px;
}

.stat-number {
    font-size: 1.25rem;
    font-weight: 700;
    color: #ffffff !important;
    margin-bottom: 1px;
}

.stat-label {
    font-size: 0.75rem;
    color: #ffffff !important;
    font-weight: 500;
}

@media (max-width: 640px) {
    .header-content {
        flex-direction: column;
        gap: 16px;
    }
    
    .header-text {
        text-align: center;
    }
}

.campaigns-grid {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
}

.campaign-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    border: 1px solid #eef2f6;
    overflow: hidden;
    position: relative;
    max-height: 390px;
    display: flex;
    flex-direction: column;
    height: 370px;
}

.campaign-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
}

.campaign-card.category-crypto::before {
    background: #10B981;
}

.campaign-card.category-investing::before {
    background: #0088CC;
}

.campaign-card.category-budgeting::before {
    background: #28A745;
}

.campaign-card.category-banking::before {
    background: #6610F2;
}

.campaign-card.category-retirement::before {
    background: #E83E8C;
}

.campaign-card.category-fintech::before {
    background: #20C997;
}

.campaign-card.category-realestate::before {
    background: #FD7E14;
}

.campaign-card.category-credit::before {
    background: #DC3545;
}

.campaign-card.category-education::before {
    background: #17A2B8;
}

.campaign-card.category-student::before {
    background: #198754;
}

.campaign-card.category-business::before {
    background: #6F42C1;
}

.campaign-card.category-sustainable::before {
    background: #2EA44F;
}

.campaign-card.category-innovation::before {
    background: #D63384;
}

.campaign-card.category-security::before {
    background: #343A40;
}

.campaign-card.category-default::before {
    background: #6C757D;
}

.campaign-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    border-color: #e2e8f0;
}

.campaign-list-header {
    padding: 20px;
    display: flex;
    gap: 16px;
    height: 160px;
    border-bottom: 1px solid #edf2f7;
}

.campaign-list-image {
    width: 64px;
    height: 64px;
    border-radius: 12px;
    overflow: hidden;
    flex-shrink: 0;
}

.campaign-list-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.placeholder-image {
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #4f46e5, #7c3aed);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
}

.campaign-list-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.campaign-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0 0 8px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.4;
    height: 2.8em;
}

.campaign-brand {
    font-size: 0.9rem;
    color: #666;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.4;
    height: 4.2em;
    margin-bottom: 4px;
}

.campaign-content {
    padding: 16px 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.campaign-inner-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.campaign-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.payout-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.payout-amount {
    font-size: 1.25rem;
    font-weight: 700;
    color: #2d3748;
}

.payout-label {
    font-size: 0.75rem;
    color: #718096;
}

.campaign-category {
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 0.85rem;
    font-weight: 500;
    white-space: nowrap;
}

/* Individual Category Colors */
.category-crypto {
    background-color: #ECFDF5;
    color: #10B981;
}

.category-investing {
    background-color: #E5F6FF;
    color: #0088CC;
}

.category-budgeting {
    background-color: #E5FFE5;
    color: #28A745;
}

.category-banking {
    background-color: #F0E5FF;
    color: #6610F2;
}

.category-retirement {
    background-color: #FFE5F6;
    color: #E83E8C;
}

.category-fintech {
    background-color: #E5FFF6;
    color: #20C997;
}

.category-realestate {
    background-color: #FFF3E5;
    color: #FD7E14;
}

.category-credit {
    background-color: #FFE5E5;
    color: #DC3545;
}

.category-education {
    background-color: #E5F6FF;
    color: #17A2B8;
}

.category-student {
    background-color: #E5FFE5;
    color: #198754;
}

.category-business {
    background-color: #F0E5FF;
    color: #6F42C1;
}

.category-sustainable {
    background-color: #E5FFE5;
    color: #2EA44F;
}

.category-innovation {
    background-color: #FFE5F6;
    color: #D63384;
}

.category-security {
    background-color: #E5E5E5;
    color: #343A40;
}

.category-default {
    background-color: #F8F9FA;
    color: #6C757D;
}

/* Hover effects */
.campaign-category:hover {
    filter: brightness(0.95);
}

.campaign-actions {
    margin-top: auto;
    text-align: center;
}

.learn-more-btn {
    background: transparent;
    color: #4a5568;
    border: 1px solid #e2e8f0;
    padding: 8px 20px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
    width: auto;
    min-width: 120px;
}

.learn-more-btn:hover {
    background: #f7fafc;
    border-color: #cbd5e0;
    transform: translateY(-1px);
    color: #2d3748;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Category-specific button hover states */
.category-finance .learn-more-btn:hover {
    color: #2c5282;
    border-color: #bee3f8;
    background: #ebf8ff;
}

.category-crypto .learn-more-btn:hover {
    color: #553c9a;
    border-color: #e9d8fd;
    background: #faf5ff;
}

.category-tech .learn-more-btn:hover {
    color: #276749;
    border-color: #c6f6d5;
    background: #f0fff4;
}

.category-default .learn-more-btn:hover {
    color: #4a5568;
    border-color: #e2e8f0;
    background: #f7fafc;
}

.campaigns-layout {
    display: grid;
    grid-template-columns: 280px 1fr;
    gap: 24px;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 24px;
    position: relative;
}

.campaigns-filters {
    background: white;
    border-radius: 12px;
    padding: 24px;
    height: fit-content;
    position: sticky;
    top: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.filters-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #edf2f7;
}

.filters-header h3 {
    font-size: 1.1rem;
    font-weight: 600;
    color: #2d3748;
    margin: 0;
}

.filters-header button {
    color: #4a5568;
    background: none;
    border: none;
    font-size: 0.9rem;
    cursor: pointer;
}

.filter-group {
    margin-bottom: 20px;
}

.filter-group label {
    display: block;
    font-size: 0.9rem;
    color: #4a5568;
    margin-bottom: 8px;
    font-weight: 500;
}

.filter-group select,
.filter-group input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.95rem;
    color: #2d3748;
    background: white;
}

.range-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}

.range-inputs input {
    width: 100%;
}

@media (max-width: 768px) {
    .campaigns-layout {
        grid-template-columns: 1fr;
    }

    .campaigns-filters {
        position: static;
        margin-bottom: 24px;
    }
}

.create-campaign-btn {
    display: block;
    background: #ffffff;
    color: #1a365d;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-left: 16px;
    z-index: 10;
}

.create-campaign-btn:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    background: #f8fafc;
}

.create-campaign-btn::before {
    content: '+';
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1;
}

@media (max-width: 640px) {
    .header-content {
        flex-direction: column;
        gap: 16px;
    }
    
    .create-campaign-btn {
        width: 100%;
        justify-content: center;
    }
}

.create-campaign-card {
    background: white;
    border-radius: 12px;
    border: 2px dashed #e2e8f0;
    padding: 32px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    text-align: center;
}

.create-campaign-card:hover {
    border-color: #2563eb;
    background: #f8fafc;
    transform: translateY(-2px);
}

.create-campaign-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.plus-icon {
    width: 48px;
    height: 48px;
    background: #2563eb;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-bottom: 8px;
}

.create-campaign-content h3 {
    color: #1a365d;
    margin: 0;
    font-size: 1.25rem;
}

.create-campaign-content p {
    color: #64748b;
    margin: 0;
    font-size: 0.9rem;
}

/* Add these styles for the loading state */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
}

.loading-spinner {
  color: #1a365d;
  font-size: 1.2rem;
}

/* Replace info-banner styles with these */
.campaign-promo-banner {
  background: linear-gradient(to right, #f8f9fa, #e9ecef);
  border-radius: 8px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  max-width: 930px;
}

.promo-content {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.promo-icon {
  font-size: 24px;
  color: #0066cc;
}

.promo-text {
  flex: 1;
}

.promo-text h3 {
  margin: 0 0 8px 0;
  color: #2c3e50;
  font-size: 1.2rem;
  font-weight: 600;
}

.promo-text p {
  margin: 0;
  color: #5a6c7d;
  line-height: 1.5;
  font-size: 0.95rem;
}

@media (max-width: 768px) {
  .campaign-promo-banner {
    margin: 16px;
  }
  
  .promo-content {
    flex-direction: column;
    gap: 12px;
  }
  
  .promo-text h3 {
    font-size: 1.1rem;
  }
  
  .promo-text p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 768px) {
    .campaigns-stats .stat-item {
      display: none;
    }
    
    .campaigns-header .header-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 16px;
    }
  
    .campaigns-stats {
      width: 100%;
    }
  
    .create-campaign-btn {
      width: 100%;
    }
  }

/* Add styles for mobile filter toggle button */
.filter-toggle-btn {
    display: none;
    align-items: center;
    gap: 8px;
    background: #fff;
    border: 1px solid #e2e8f0;
    padding: 12px 20px;
    border-radius: 8px;
    font-weight: 500;
    color: #4a5568;
    margin-bottom: 16px;
    width: 100%;
    justify-content: center;
    cursor: pointer;
}

.filter-toggle-btn svg {
    width: 20px;
    height: 20px;
    transition: transform 0.2s ease;
}

.filter-toggle-btn.active svg {
    transform: rotate(180deg);
}

@media (max-width: 768px) {
    .campaigns-section {
        padding: 16px;
    }

    .campaigns-layout {
        grid-template-columns: 1fr;
        padding: 0;
    }

    .filter-toggle-btn {
        display: flex;
    }

    .campaigns-filters {
        display: none;
        position: static;
        margin-bottom: 24px;
        width: 100%;
    }

    .campaigns-filters.show {
        display: block;
    }

    .campaigns-grid {
        grid-template-columns: 1fr;
    }
}

/* Update campaign card styles for mobile */
@media (max-width: 480px) {
    .campaign-card {
        height: auto;
        min-height: 300px;
    }

    .campaign-list-header {
        height: auto;
        min-height: 140px;
    }

    .campaign-details {
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin-bottom: 16px;
        width: 100%;
    }

    .campaign-category {
        width: 100%;
        text-align: center;
    }

    .payout-info {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .payout-amount {
        font-size: 1.1rem;
        text-align: center;
    }

    .payout-label {
        text-align: center;
    }
}

/* Replace or add these media queries at the end of your file */
@media (max-width: 768px) {
    .campaigns-container {
        width: 100%;
        padding: 0 12px;
    }

    .campaign-promo-banner {
        margin: 16px 0;
        padding: 16px;
    }

    .campaigns-header {
        margin: 0 0 16px 0;
        padding: 16px;
    }

    .campaigns-section {
        padding: 16px;
    }

    .campaigns-layout {
        grid-template-columns: 1fr;
        padding: 0;
    }

    .filter-toggle-btn {
        display: flex;
    }

    .campaigns-filters {
        display: none;
        position: static;
        margin-bottom: 24px;
        width: 100%;
    }

    .campaigns-filters.show {
        display: block;
    }

    .campaigns-grid {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 480px) {
    .campaign-card {
        height: auto;
        min-height: auto;
        padding-bottom: 16px;
    }

    .campaign-list-header {
        height: auto;
        min-height: auto;
        padding: 16px;
        flex-direction: row;
        align-items: flex-start;
    }

    .campaign-list-image {
        width: 48px;
        height: 48px;
        border-radius: 8px;
    }

    .campaign-list-info {
        flex: 1;
        min-height: auto;
    }

    .campaign-title {
        font-size: 1rem;
        line-height: 1.4;
        height: auto;
        margin-bottom: 8px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .campaign-brand {
        font-size: 0.85rem;
        line-height: 1.4;
        height: auto;
        margin-bottom: 0;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .campaign-content {
        padding: 0px 16px;
    }

    .campaign-details {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        margin-bottom: 16px;
    }

    .payout-info {
        width: 100%;
    }

    .payout-amount {
        font-size: 1.1rem;
    }

    .campaign-category {
        width: 100%;
        text-align: center;
        padding: 8px 12px;
        font-size: 0.8rem;
    }

    .campaign-actions {
        width: 100%;
    }

    .learn-more-btn {
        width: 100%;
        padding: 10px 16px;
    }

    .campaigns-section {
        padding: 12px;
    }

    .campaigns-container {
        padding: 0;
    }

    .campaigns-grid {
        gap: 16px;
        padding: 0;
    }
}
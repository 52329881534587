/* Creator Offers Grid Styles */
.creator-offers-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.offer-preview-card {
    background: white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.offer-preview-card:hover {
    transform: translateY(-2px);
}

.offer-preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
}

.offer-preview-header h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #2D3748;
}

.offer-price {
    font-size: 1.3rem;
    font-weight: 600;
    color: #38A169;
}

.offer-price::before {
    content: "$";
}

.offer-preview-details {
    color: #4A5568;
}

.offer-delivery {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.offer-delivery .label {
    font-weight: 500;
}

.offer-includes h4 {
    margin: 0 0 10px 0;
    font-size: 1rem;
    color: #2D3748;
}

.offer-includes ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.offer-includes li {
    padding: 5px 0;
    position: relative;
    padding-left: 20px;
}

.offer-includes li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: #38A169;
}

.no-offers-message {
    grid-column: 1 / -1;
    text-align: center;
    padding: 40px;
    color: #718096;
    font-style: italic;
}

/* Additional Offer Card Styles */
.offer-card {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transition: transform 0.2s ease;
    border: 1px solid rgba(0, 0, 0, 0.05);
    max-width: 300px;
}

.offer-card:hover {
    transform: translateY(-4px);
}

.offer-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 12px;
}

.offer-title-section h3 {
    font-size: 1.3rem;
    color: #2d3748;
    margin: 0;
    font-weight: 600;
}

.creator-offer-price-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    margin: 12px 0;
}

.creator-offer-price-amount {
    font-size: 1.75rem;
    font-weight: 600;
    color: black;
    line-height: 1.2;
    margin: 0;
}

.creator-offer-price-label {
    font-size: 0.875rem;
    color: #4a5568;
    margin: 0;
    line-height: 1;
}

.offer-details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    background: #f7fafc;
    padding: 15px;
    border-radius: 8px;
    margin: 15px 0;
}

.offer-detail {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.detail-label {
    font-size: 0.75rem !important;
    color: #718096;
    font-weight: 500;
}

.detail-value {
    font-size: 0.85rem !important;
    color: #2d3748;
    font-weight: 600;
}

.includes-section {
    margin-top: 15px;
}

.includes-label {
    font-size: 0.8rem !important;
    color: #4a5568;
    font-weight: 600;
    margin-bottom: 3px;
}

.includes-items {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.include-item {
    background: #edf2f7;
    padding: 6px 12px;
    border-radius: 15px;
    font-size: 0.75rem !important;
    color: #4a5568;
    display: flex;
    align-items: center;
    gap: 6px;
}

.platform-icon {
    font-size: 0.9rem;
    color: #4a5568;
}

.select-offer-button {
    width: 100%;
    padding: 10px 20px;
    background: #38a169;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 0.95rem;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.select-offer-button:hover {
    background: #2f855a;
}

.select-offer-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.7;
}

.select-offer-button.disabled:hover {
    background-color: #ccc;
    transform: none;
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 30px;
    border-radius: 12px;
    max-width: 500px;
    width: 90%;
}

.modal-actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.confirm-button,
.cancel-button {
    padding: 10px 20px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    font-weight: 500;
}

.confirm-button {
    background: #38A169;
    color: white;
}

.cancel-button {
    background: #EDF2F7;
    color: #4A5568;
}

.confirm-button:hover {
    background: #2F855A;
}

.cancel-button:hover {
    background: #E2E8F0;
}

/* Creator Details Container */
.creator-details-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.creator-gallery-hero {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
}

.creator-profile-section {
    padding: 2rem 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

/* Style for the creator avatar */
.creator-avatar-container img {
    object-fit: cover;
}

/* Style for the social media badge */
.creator-info-container .social-badge {
    display: inline-flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 6px 12px;
    border-radius: 20px;
    margin-right: 10px;
    font-size: 14px;
}

.creator-info-container .social-badge svg {
    margin-right: 6px;
    color: #333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .creator-gallery-hero {
        margin-bottom: 10px;
    }
    
    .creator-profile-section {
        text-align: center;
    }
}

/* Reset any Chakra margins */
.creator-section,
.platform-card,
.creator-hero,
.creator-card-glass {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
}

/* Force center alignment */
@media (max-width: 428px) {
    .creator-details-container,
    .creator-details-content {
        transform: translateX(0) !important;
        left: 0 !important;
        right: 0 !important;
        position: relative !important;
    }
}

/* Hero Section */
.creator-hero {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 30px;
    max-width: 1200px;
    margin: 0 auto;
}

.creator-card-glass {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.profile-card {
    padding: 24px;
}

.profile-info-group {
    display: flex;
    gap: 24px;
}

.creator-profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
}

.creator-info {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;
}

.creator-info h1 {
    margin: 0;
    font-size: 2rem;
    color: #2D3748;
    font-weight: 600;
    line-height: 1.2;
}

.creator-meta {
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 0.875rem;
}

.meta-item {
    color: #2D3748;
    display: flex;
    align-items: center;
    gap: 8px;
}

.meta-item strong {
    color: #2D3748;
    font-weight: 600;
    min-width: 80px;
}

/* Stats Card */
.stats-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    padding: 2rem;
    gap: 2rem;
}

.stats-total-followers {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.stats-total-count {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2D3748;
    line-height: 1.2;
}

.stats-total-label {
    font-size: 1rem;
    color: #718096;
    margin-top: 0.25rem;
}

.stats-social-links {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
}

.stats-social-link {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    transition: transform 0.2s;
}

.stats-social-link:hover {
    transform: translateY(-2px);
}

/* Keep social media colors */
.stats-social-link.instagram { background: #E1306C; }
.stats-social-link.tiktok { background: #000000; }
.stats-social-link.youtube { background: #FF0000; }
.stats-social-link.twitter { background: #1DA1F2; }
.stats-social-link.facebook { background: #4267B2; }
.stats-social-link.twitch { background: #9146FF; }

/* Follower Stats Section */
.creator-follower-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 10px;
    margin: 15px 0;
    padding: 5px;
}

.follower-stat-card {
    background: white;
    border-radius: 10px;
    padding: 16px 12px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.follower-stat-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.follower-stat-card.total-followers {
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: white;
    grid-column: auto;
}

.follower-stat-card.total-followers .follower-stat-value {
    font-size: 1.3rem !important;
    background: -webkit-linear-gradient(45deg, #fff, #e2e8f0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
}

.follower-stat-card.total-followers .stat-icon {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.8rem !important;
}

.follower-stat-card.instagram {
    background: linear-gradient(45deg, #833AB4, #FD1D1D, #F77737);
}

.follower-stat-card.tiktok {
    background: linear-gradient(45deg, #010101, #69C9D0);
}

.follower-stat-card.youtube {
    background: linear-gradient(45deg, #FF0000, #282828);
}

.follower-stat-card.twitter {
    background: linear-gradient(45deg, #1DA1F2, #14171A);
}

.follower-stat-card.facebook {
    background: linear-gradient(45deg, #4267B2, #18243D);
}

.follower-stat-card.twitch {
    background: linear-gradient(45deg, #9146FF, #000000);
}

.follower-stat-info {
    position: relative;
    z-index: 2;
}

.stat-icon {
    font-size: 1.8rem !important;
    margin-bottom: 8px;
    color: white;
}

.follower-stat-value {
    display: block;
    font-size: 1.3rem !important;
    font-weight: 700;
    color: white;
    margin-bottom: 4px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    line-height: 1.2;
}

.follower-stat-label {
    display: block;
    font-size: 0.85rem !important;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.02em;
}

/* Add subtle hover animation */
.follower-stat-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.1), transparent);
    transform: translateX(-100%);
    transition: transform 0.6s;
}

.follower-stat-card:hover::after {
    transform: translateX(100%);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .creator-follower-stats {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        gap: 8px;
    }

    .follower-stat-value {
        font-size: 1.2rem !important;
    }

    .follower-stat-label {
        font-size: 0.8rem !important;
    }
    
    .stat-icon {
        font-size: 1.6rem !important;
    }
}

/* Platform specific colors */
.instagram .stat-icon { color: #E1306C; }
.tiktok .stat-icon { color: #000000; }
.youtube .stat-icon { color: #FF0000; }
.twitter .stat-icon { color: #1DA1F2; }
.facebook .stat-icon { color: #4267B2; }
.twitch .stat-icon { color: #9146FF; }

/* Creator Sections */
.creator-section {
    margin-bottom: 3rem;
    padding: 1rem;
}

.creator-section h2 {
    margin: 0 0 20px 0;
    color: #2D3748;
}

/* Bio Section */
.creator-bio {
    color: #4A5568;
    line-height: 1.6;
}

/* Social Platforms Grid */
.social-platforms-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
    margin-top: 20px;
}

.platform-card {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.platform-info h3 {
    padding: 15px 20px;
    margin: 0;
    background: #F7FAFC;
    border-bottom: 1px solid #E2E8F0;
}

.embed-container {
    position: relative;
    padding: 15px;
    width: 100%;
    overflow: hidden;
}

.embed-container iframe {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: block;
}

.view-channel-link {
    display: block;
    text-align: center;
    padding: 10px;
    background: #F7FAFC;
    color: #4A5568;
    text-decoration: none;
    border-top: 1px solid #E2E8F0;
}

.view-channel-link:hover {
    background: #EDF2F7;
}

/* Show More Button */
.show-more-button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    background: #EDF2F7;
    border: none;
    border-radius: 6px;
    color: #4A5568;
    cursor: pointer;
    font-weight: 500;
}

.show-more-button:hover {
    background: #E2E8F0;
}

/* Portfolio Section */
.portfolio-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.portfolio-item {
    background: #F7FAFC;
    padding: 20px;
    border-radius: 8px;
}

/* Demographics Section */
.audience-info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}

.audience-card {
    background: #F7FAFC;
    padding: 20px;
    border-radius: 8px;
}

.age-ranges,
.location-tags,
.interest-tags,
.topic-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 10px;
}

.age-range-item,
.location-tag,
.interest-tag,
.topic-tag {
    background: white;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 0.875rem;
    color: #4A5568;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Engagement Section */
.engagement-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.engagement-card {
    background: #F7FAFC;
    padding: 20px;
    border-radius: 8px;
}

.metric-item {
    margin-bottom: 15px;
}

.metric-item:last-child {
    margin-bottom: 0;
}

/* Loading and Error States */
.loading-container,
.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
}

.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Brand Collaborations Section */
.collaborations-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 24px;
    margin-top: 20px;
    padding: 10px;
}

.collaboration-card {
    background: white;
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 
                0 10px 15px rgba(0, 0, 0, 0.03);
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.05);
    height: fit-content;
}

.collaboration-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08), 
                0 16px 24px rgba(0, 0, 0, 0.06);
}

.collab-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f0f0f0;
}

.collab-header h3 {
    margin: 0;
    font-size: 1.25rem;
    color: #1a202c;
    font-weight: 600;
}

.collab-date {
    color: #718096;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 6px;
}

.collab-content {
    color: #4A5568;
}

.collab-description {
    margin-bottom: 20px;
    line-height: 1.6;
    font-size: 0.95rem;
    color: #4a5568;
}

.collab-metrics {
    background: #F7FAFC;
    padding: 16px;
    border-radius: 12px;
    margin-top: 20px;
    border: 1px solid #E2E8F0;
}

.collab-metrics h4 {
    margin: 0 0 8px 0;
    font-size: 0.875rem;
    color: #4A5568;
    text-transform: uppercase;
    letter-spacing: 0.025em;
}

.collab-metrics p {
    margin: 0;
    color: #38A169;
    font-weight: 600;
    font-size: 1.125rem;
}

.brand-name {
    font-weight: 600;
    color: #2D3748;
    display: inline-block;
    padding: 6px 12px;
    background: #EDF2F7;
    border-radius: 20px;
    font-size: 0.9rem;
}

/* Add a subtle gradient background to the section */
.brand-collaborations-section {
    padding: 30px 0;
    background: linear-gradient(to bottom, #ffffff, #f7fafc);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .collaborations-grid {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        gap: 16px;
        padding: 5px;
    }

    .collaboration-card {
        padding: 20px;
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .profile-info-group {
        flex-direction: column;
        align-items: center!important;
        text-align: center!important;
        gap: 15px;
    }

    .creator-profile-image {
        width: 120px;
        height: 120px;
    }

    .creator-info h1 {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }

    .creator-meta {
        flex-direction: column;
        gap: 8px;
        align-items: center;
    }

    .meta-item {
        text-align: center;
    }

    .stats-card {
        padding: 15px;
    }

    .total-followers-display {
        text-align: center;
    }

    .social-links {
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px;
    }

    .followers-grid {
        grid-template-columns: 1fr;
        gap: 15px;
        padding: 10px;
    }

    .audience-info-grid {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .collaborations-grid {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .creator-offers-grid {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .platform-embeds {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .embed-container {
        margin: 0;
        width: 100%;
    }

    .creator-section {
        padding: 15px;
        margin-bottom: 15px;
    }

    .section-header-with-toggle {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }

    .toggle-embeds-button {
        width: 100%;
    }
}

/* Extra small screens */
@media (max-width: 480px) {
    .creator-profile-image {
        width: 100px;
        height: 100px;
    }

    .creator-info h1 {
        font-size: 1.3rem;
    }

    .meta-item {
        font-size: 0.9rem;
    }

    .total-followers-display .total-count {
        font-size: 1.5rem;
    }

    .social-link {
        font-size: 1.2rem;
    }
}

/* Mobile-first responsive adjustments */
@media (max-width: 768px) {
    .creator-hero {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .profile-card,
    .stats-card {
        width: 100%;
        padding: 16px;
    }

    /* Center stats content on mobile */
    .stats-card {
        text-align: center;
    }

    .total-followers-display {
        margin-bottom: 12px;
    }
}

/* Extra small screens */
@media (max-width: 480px) {
    .creator-details-content {
        padding: 10px;
        align-items: center!important;
    }

    .platform-card {
        margin: 5px 0;
    }

    .embed-container {
        margin: 0;
    }
}

/* Tighter mobile layout */
@media (max-width: 768px) {
    .creator-details-container {
        width: 100vw;
        overflow-x: hidden;
        padding: 0;
    }

    .creator-details-content {
        padding: 8px;
        width: 100%;
    }

    .creator-hero {
        padding: 8px;
        margin: 0;
    }

    .platform-card {
        width: 100%;
        margin: 5px 0;
    }

    .embed-container {
        width: 100%;
        max-width: calc(100vw - 20px);
        margin: 0;
    }

    .embed-container iframe {
        width: 100% !important;
        max-width: 100%;
    }

    /* Tighter spacing */
    .creator-section {
        padding: 10px;
        margin: 5px 0;
    }

    /* Adjust social embeds specifically */
    .social-platforms-grid {
        width: 100%;
        padding: 0;
    }

    /* Force Twitter timeline to be contained */
    iframe[id^='twitter-widget'] {
        width: 100% !important;
        max-width: 100% !important;
    }

    /* Tighter card layouts */
    .creator-card-glass,
    .platform-card,
    .portfolio-item,
    .collaboration-card {
        padding: 10px;
        margin: 5px 0;
        width: 100%;
        max-width: calc(100vw - 16px);
    }
}

/* iPhone-specific adjustments */
@media (max-width: 428px) {
    .creator-details-content {
        padding: 5px;
    }

    .embed-container {
        max-width: calc(100vw - 10px);
    }

    .platform-card {
        margin: 3px 0;
    }

    .creator-section {
        padding: 8px;
        margin: 3px 0;
    }
}

/* Fix scrollbar and container issues */
.creator-details-container {
    -webkit-overflow-scrolling: touch; /* Smooth scroll on iOS */
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    position: relative;
    max-width: 100vw;
}

/* Hide scrollbar for WebKit browsers while keeping functionality */
.creator-details-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Firefox */
.creator-details-container {
    scrollbar-width: none;
}

/* Prevent internal scroll issues on iOS */
.embed-container,
.platform-card,
.creator-section {
    -webkit-overflow-scrolling: touch;
    overflow: hidden; /* Prevent internal scrolling */
    max-width: 100%;
}

/* Fix iframe container */
.embed-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    -webkit-transform: translateZ(0); /* Force hardware acceleration */
    transform: translateZ(0);
}

@media (max-width: 428px) {
    body {
        overflow-x: hidden; /* Prevent body overflow */
    }
    
    .creator-details-container {
        position: relative;
        width: 100vw;
        left: 0;
        right: 0;
    }

    /* Preview container adjustments */
    .creator-details-container {
        width: 100%;
        max-width: 100vw;
        padding: 0;
        margin: 0;
    }

    /* Tighter hero section */
    .creator-hero {
        padding: 5px;
    }

    .creator-profile-image {
        width: 80px;
        height: 80px;
    }

    /* Compact meta information */
    .creator-meta {
        gap: 5px;
        font-size: 0.9rem;
    }

    .meta-item {
        padding: 3px 6px;
    }

    /* Adjust social stats */
    .creator-follower-stats {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
        padding: 5px;
    }

    .follower-stat-card {
        padding: 8px;
        font-size: 0.85rem;
    }

    .follower-stat-value {
        font-size: 1rem;
    }

    /* Platform embeds */
    .social-platforms-grid {
        gap: 8px;
    }

    .platform-card {
        margin: 2px 0;
        padding: 8px;
    }

    .embed-container {
        margin: 5px 0;
        height: auto;
    }

    /* Adjust iframe heights */
    .embed-container iframe {
        height: 300px !important;
    }

    .embed-container iframe[src*="instagram"] {
        height: 400px !important;
    }

    .embed-container iframe[src*="tiktok"] {
        height: 500px !important;
    }

    /* Portfolio and other sections */
    .portfolio-grid,
    .collaborations-grid,
    .audience-info-grid {
        gap: 8px;
    }

    .portfolio-item,
    .collaboration-card,
    .audience-card {
        padding: 8px;
        margin: 2px 0;
    }

    /* Text size adjustments */
    h2 {
        font-size: 1.2rem;
        margin: 8px 0;
    }

    h3 {
        font-size: 1.1rem;
    }

    p {
        font-size: 0.9rem;
        line-height: 1.4;
    }
}

/* Extra tight layout for smaller iPhones */
@media (max-width: 375px) {
    .creator-profile-image {
        width: 70px;
        height: 70px;
    }

    .meta-item {
        font-size: 0.8rem;
    }

    .embed-container iframe {
        height: 250px !important;
    }
}

/* Override Chakra container styles */
.chakra-container,
[class*="chakra-container-"] {
    max-width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    overflow-x: hidden !important;
}

/* Force preview container width */
.preview-container,
[class*="preview-"] {
    width: 100% !important;
    max-width: 100vw !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden !important;
    position: relative !important;
    box-sizing: border-box !important;
}

/* Reset any potential Chakra grid styles */
.creator-details-content > div,
.creator-section > div {
    width: 100% !important;
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

/* Ensure embeds stay contained */
.embed-container {
    width: calc(100% - 16px) !important;
    margin: 8px !important;
}

/* Mobile-specific offer card styles */
@media (max-width: 768px) {
    .creator-offers-grid {
        display: grid;
        grid-template-columns: 1fr; /* Stack cards on mobile */
        gap: 12px;
        padding: 8px;
    }

    .offer-card {
        width: 100%;
        max-width: 100%;
        padding: 12px;
        margin: 0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }

    .offer-header {
        padding: 8px 0;
    }

    .offer-header h3 {
        font-size: 1.05rem !important;
        margin-bottom: 4px;
    }

    .creator-offer-price-wrapper {
        margin: 8px 0;
    }

    .creator-offer-price-amount {
        font-size: 1.25rem !important;
    }

    .creator-offer-price-label {
        font-size: 0.75rem !important;
    }

    .offer-details {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Adjust for smaller screens */
        gap: 6px; /* Reduced gap */
        padding: 6px; /* Reduced padding */
        margin: 6px 0; /* Reduced margin */
    }

    .detail-label {
        font-size: 0.7rem !important;
    }

    .detail-value {
        font-size: 0.8rem !important;
    }

    .includes-section {
        margin-top: 6px; /* Reduced margin */
    }

    .includes-label {
        font-size: 0.75rem !important;
        margin-bottom: 3px;
    }

    .includes-items {
        gap: 3px; /* Reduced gap */
    }

    .include-item {
        font-size: 0.7rem !important;
        padding: 4px 8px;
    }

    .select-offer-button {
        margin-top: 10px;
        padding: 8px;
        font-size: 0.85rem !important;
    }
}

/* Adjust offer grid layout */
.creator-offers-grid {
    max-width: 900px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .creator-details-container {
        padding: 0 12px;
    }

    .creator-offers-grid {
        gap: 12px;
    }
}

.platform-card.tiktok .embed-container {
    position: relative;
    width: 100%;
    max-width: 605px;
    margin: 0 auto;
    overflow: hidden;
}

.tiktok-embed {
    margin: 0 auto;
}

/* Mobile-optimized offer card styles */
@media (max-width: 428px) {
    .creator-offers-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 12px;
        padding: 8px;
        width: 100%;
    }

    .offer-card {
        width: 100%;
        max-width: 100%;
        padding: 12px;
        margin: 0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }

    .offer-header {
        padding: 8px 0;
    }

    .offer-header h3 {
        font-size: 1rem !important;
        margin-bottom: 4px;
    }

    .creator-offer-price-wrapper {
        margin: 8px 0;
    }

    .creator-offer-price-amount {
        font-size: 1.2rem !important;
    }

    .creator-offer-price-label {
        font-size: 0.75rem !important;
    }

    .offer-details {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
        padding: 8px;
        margin: 8px 0;
    }

    .detail-label {
        font-size: 0.7rem !important;
    }

    .detail-value {
        font-size: 0.8rem !important;
    }

    .includes-section {
        margin-top: 8px;
    }

    .includes-label {
        font-size: 0.75rem !important;
        margin-bottom: 4px;
    }

    .includes-items {
        gap: 4px;
    }

    .include-item {
        font-size: 0.7rem !important;
        padding: 3px 6px;
    }

    .select-offer-button {
        margin-top: 12px;
        padding: 8px;
        font-size: 0.95rem !important;
    }
}

.tiktok-thumbnail-container {
    position: relative;
    width: 100%;
    height: 500px;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    overflow: hidden;
}

.thumbnail-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.1);
    transition: background 0.2s ease;
}

.tiktok-thumbnail-container:hover .thumbnail-overlay {
    background: rgba(0, 0, 0, 0.2);
}

.play-button {
    width: 60px;
    height: 60px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
    transition: transform 0.2s ease;
}

.tiktok-thumbnail-container:hover .play-button {
    transform: scale(1.1);
}

.click-to-play {
    position: absolute;
    bottom: 20px;
    color: #333;
    font-size: 14px;
    font-weight: 500;
}

@media (max-width: 768px) {
    .tiktok-thumbnail-container {
        height: 400px;
    }
    
    .play-button {
        width: 50px;
        height: 50px;
        font-size: 20px;
    }
}

.profile-actions {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid rgba(229, 231, 235, 0.5);
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
    .creator-offers-grid {
        display: grid;
        grid-template-columns: 1fr; /* Stack cards on mobile */
        gap: 12px;
        padding: 8px;
    }

    .offer-card {
        width: 100%;
        max-width: 100%;
        padding: 12px;
        margin: 0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }

    .offer-header {
        padding: 8px 0;
    }

    .offer-header h3 {
        font-size: 1.05rem !important;
        margin-bottom: 4px;
    }

    .creator-offer-price-wrapper {
        margin: 8px 0;
    }

    .creator-offer-price-amount {
        font-size: 1.25rem !important;
    }

    .creator-offer-price-label {
        font-size: 0.75rem !important;
    }

    .offer-details {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Adjust for smaller screens */
        gap: 6px; /* Reduced gap */
        padding: 6px; /* Reduced padding */
        margin: 6px 0; /* Reduced margin */
    }

    .detail-label {
        font-size: 0.7rem !important;
    }

    .detail-value {
        font-size: 0.8rem !important;
    }

    .includes-section {
        margin-top: 6px; /* Reduced margin */
    }

    .includes-label {
        font-size: 0.75rem !important;
        margin-bottom: 3px;
    }

    .includes-items {
        gap: 3px; /* Reduced gap */
    }

    .include-item {
        font-size: 0.7rem !important;
        padding: 4px 8px;
    }

    .select-offer-button {
        margin-top: 10px;
        padding: 8px;
        font-size: 0.85rem !important;
    }
}

/* Add these new styles for the packages section */
.packages-section {
    padding: 30px;
    margin-bottom: 30px;
}

.package-filter-tabs {
    display: flex;
    margin-bottom: 24px;
    overflow-x: auto;
    padding-bottom: 4px;
}

.filter-tab {
    padding: 8px 24px;
    border-radius: 50px !important;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    white-space: nowrap;
    transition: all 0.2s ease;
}

.filter-tab.active {
    background-color: #ed64a6 !important;
    color: white !important;
}

.packages-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.package-card {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    background: white;
}

.package-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.package-card h3 {
    font-size: 18px;
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 8px;
}

.package-card p {
    color: #4a5568;
    font-size: 14px;
    line-height: 1.5;
}

.package-card .price {
    font-size: 24px;
    font-weight: 700;
    color: #2d3748;
}

.package-card .platform-badge {
    display: flex;
    align-items: center;
    padding: 12px;
    background: #f7fafc;
    border-top: 1px solid #e2e8f0;
}

.package-card .platform-badge svg {
    margin-right: 8px;
    color: #4a5568;
}

.package-card .see-more {
    color: #3182ce;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

.package-card .see-more:hover {
    text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .packages-grid {
        grid-template-columns: 1fr;
    }
    
    .package-filter-tabs {
        justify-content: flex-start;
        margin-bottom: 16px;
    }
    
    .filter-tab {
        padding: 6px 16px;
        font-size: 13px;
    }
}

.platform-tabs {
    display: flex;
    overflow-x: auto;
    gap: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    -webkit-overflow-scrolling: touch;
}

.platform-tabs::-webkit-scrollbar {
    display: none;
}

.embed-container {
    width: 100%;
    min-height: 500px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
}

.instagram-embed-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .creator-details-container {
        padding: 0;
    }

    .creator-profile-section {
        padding: 1rem;
    }

    .packages-grid {
        grid-template-columns: 1fr;
    }

    .embed-container {
        min-height: 400px;
    }
}

/* Loading spinner */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Error container */
.error-container {
    text-align: center;
    padding: 2rem;
    color: #e74c3c;
}

/* Modal styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
}

.modal-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.confirm-button,
.cancel-button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.confirm-button {
    background: #3498db;
    color: white;
}

.cancel-button {
    background: #e74c3c;
    color: white;
}
.admin-campaigns-container {
    padding: 24px;
}

.admin-campaigns-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.admin-campaigns-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 24px;
}

.admin-campaign-card {
    background: white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #eef2f6;
}

.admin-campaign-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
}

.admin-campaign-header h3 {
    margin: 0;
    font-size: 18px;
    color: #2B2D42;
    flex: 1;
}

.admin-status-badge {
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
}

.admin-status-active {
    background: #E8F5E9;
    color: #2E7D32;
}

.admin-status-paused {
    background: #FFF3E0;
    color: #EF6C00;
}

.admin-status-ended {
    background: #FFEBEE;
    color: #C62828;
}

.admin-campaign-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
}

.admin-detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #eef2f6;
}

.admin-detail-row:last-child {
    border-bottom: none;
}

.admin-detail-row strong {
    color: #6B7280;
    font-size: 14px;
    font-weight: 500;
}

.admin-detail-row span {
    color: #1F2937;
    font-size: 14px;
}

.admin-campaign-description {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #eef2f6;
}

.admin-campaign-description p {
    color: #4B5563;
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
}

.admin-campaign-actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.admin-edit-button {
    background: #2B2D42;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
}

.admin-edit-button:hover {
    background: #1F2937;
}

.admin-create-campaign-btn {
    background: #2B2D42;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.admin-create-campaign-btn:hover {
    background: #1F2937;
}

.admin-loading-state,
.admin-error-message,
.admin-no-campaigns {
    text-align: center;
    padding: 40px;
    color: #6B7280;
}

.admin-error-message {
    color: #DC2626;
} 
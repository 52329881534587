.profile-container {
    display: flex;
    min-height: 100vh;
    background: #f8f9fa;
  }
  
  .profile-sidebar {
    width: 280px;
    background: white;
    padding: 2rem;
    border-right: 1px solid #eee;
    position: sticky;
    top: 0;
    height: auto;
    min-height: 100vh;
    overflow-y: visible;
  }
  
  .profile-sidebar h1 {
    margin: 0 0 2rem 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .sidebar-section {
    margin-bottom: 2rem;
  }
  
  .sidebar-section h2 {
    font-size: 1rem;
    color: #666;
    margin: 0 0 1rem 0;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  
  .sidebar-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #333;
    text-decoration: none;
    border-radius: 6px;
    margin-bottom: 0.25rem;
  }
  
  .sidebar-link:hover {
    background: #f8f9fa;
  }
  
  .sidebar-link.active {
    background: #e9ecef;
    color: #007bff;
    font-weight: 500;
  }
  
  .danger-button {
    width: 100%;
    padding: 0.75rem;
    background: #dc3545;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .danger-button:hover {
    background: #c82333;
  }
  
  .profile-content {
    flex: 1;

    padding: 1rem;
  }
  
  .content-section {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .section-header h2 {
    margin: 0;
    font-size: 1.5rem;
  }
  
  .edit-button {
    padding: 0.5rem 1rem;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .profile-section-card {
    background: #ffffff;
    border: 1px solid #e1e4e8;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .info-card h3 {
    margin: 0 0 1.5rem 0;
    font-size: 1.25rem;
    color: #333;
  }
  
  .profile-image-section {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .info-item {
    margin-bottom: 1.5rem;
  }
  
  .info-item label {
    display: block;
    margin-bottom: 0.5rem;
    color: #666;
    font-size: 0.9rem;
  }
  
  .info-item input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 1rem;
  }
  
  .info-item input:disabled {
    background: #f8f9fa;
    cursor: not-allowed;
  }
  
  .form-actions {
    margin-top: 2rem;
    text-align: right;
  }
  
  .save-button {
    padding: 0.75rem 1.5rem;
    background: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .save-button:hover {
    background: #218838;
  }
  
  @media (max-width: 768px) {
    .profile-container {
      flex-direction: column;
    }
  
    .profile-sidebar {
      width: 100%;
      position: static;
      height: auto;
      min-height: auto;
      border-right: none;
      border-bottom: 1px solid #eee;
    }
  
    .profile-content {
      margin-left: 0;
      padding: 1rem;
    }
  }
  
  .followers-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .follower-item {
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
  }
  
  .follower-item label {
    display: block;
    margin-bottom: 0.5rem;
    color: #666;
    font-size: 0.9rem;
  }
  
  .follower-item input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .total-followers {
   
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 8px;
    text-align: center;
    border: 1px solid #dee2e6;
  }
  
  .total-followers h4 {
    margin: 0;
    color: #495057;
    font-size: 1.1rem;
  }
  
  .follower-count {
    font-size: 2rem;
    font-weight: bold;
    color: #212529;
    margin: 0.5rem 0;
  }
  
  .last-updated {
    display: block;
    font-size: 0.8rem;
    color: #6c757d;
    margin-top: 0.5rem;
  }
  
  .info-card {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 1.5rem;
  }
  
  .info-card h3 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: #343a40;
    border-bottom: 2px solid #f8f9fa;
    padding-bottom: 0.5rem;
  }
  .creator-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.action-buttons {
    display: flex;
    gap: 1rem;
}

.edit-button, .save-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: background-color 0.2s;
}

.edit-button {
    background-color: #f0f0f0;
    color: #333;
}

.save-button {
    background-color: #4CAF50;
    color: white;
}

.edit-button:hover {
    background-color: #e0e0e0;
}

.save-button:hover {
    background-color: #45a049;
}

.edit-button i, .save-button i {
    font-size: 0.9rem;
}

.bio-textarea {
    width: 100%;
    min-height: 200px;
    padding: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
}

.bio-textarea:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.full-width {
    grid-column: 1 / -1;
}

.header-buttons {
    display: flex;
    gap: 1rem;
}

.save-profile-btn {
    padding: 0.5rem 1rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s;
}

.save-profile-btn:hover {
    background-color: #45a049;
}

.collaborations-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.collaboration-item {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1.5rem;
    position: relative;
}

.collab-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.brand-name-input {
    font-size: 1.1rem;
    font-weight: 600;
    flex: 1;
}

.date-input {
    width: 150px;
}

.collab-description {
    width: 100%;
    margin-bottom: 1rem;
    resize: vertical;
}

.metrics-input {
    width: 100%;
    color: #666;
}

.add-collab-button {
    background: #f0f0f0;
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 1rem;
    width: 100%;
    color: #666;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s;
}

.add-collab-button:hover {
    background: #e8e8e8;
    border-color: #999;
}

.remove-collab-button {
    background: transparent;
    color: #ff4444;
    border: 1px solid #ff4444;
    border-radius: 4px;
    padding: 0.4rem 0.8rem;
    font-size: 0.85rem;
    cursor: pointer;
    transition: all 0.2s;
    margin-left: auto;
}

.remove-collab-button:hover {
    background: #ff4444;
    color: white;
}

.social-links-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
}

.social-link-item {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 1.5rem;
}

.social-link-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1rem;
}

.social-link-header i {
    font-size: 1.5rem;
}

.social-link-header label {
    font-weight: 600;
    color: #333;
}

.social-link-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 0.75rem;
}

.follower-input-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.follower-count-input {
    width: 120px;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.follower-label {
    color: #666;
}

/* Platform-specific colors */
.social-link-item .fa-youtube { color: #FF0000; }
.social-link-item .fa-instagram { color: #E1306C; }
.social-link-item .fa-tiktok { color: #000000; }
.social-link-item .fa-twitter { color: #1DA1F2; }
.social-link-item .fa-facebook { color: #4267B2; }
.social-link-item .fa-twitch { color: #9146FF; }
.social-link-item .fa-linkedin { color: #0077B5; }
.social-link-item .fa-pinterest { color: #E60023; }
.social-link-item .fa-snapchat { color: #FFFC00; }

.info-card textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    font-family: inherit;
}

.info-item.full-width {
    grid-column: 1 / -1;
}

.info-item select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
}

.info-item select:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

/* Audience Section Styles */
.checkbox-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 0.75rem;
    margin-top: 0.5rem;
}

.checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    background: #f8f9fa;
    border-radius: 4px;
    font-size: 0.9rem;
    color: #495057;
    cursor: pointer;
    transition: background-color 0.2s;
}

.checkbox-label:hover {
    background: #e9ecef;
}

.checkbox-label input[type="checkbox"] {
    width: 16px;
    height: 16px;
    accent-color: #0066cc;
}

.tag-input {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.75rem;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background: #fff;
    min-height: 45px;
}

.tag-input input {
    border: none;
    outline: none;
    padding: 0.25rem;
    flex: 1;
    min-width: 150px;
    font-size: 0.9rem;
}

.tag {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: #e7f1ff;
    color: #0066cc;
    padding: 0.25rem 0.75rem;
    border-radius: 16px;
    font-size: 0.9rem;
    transition: all 0.2s;
}

.tag button {
    background: none;
    border: none;
    color: #0066cc;
    cursor: pointer;
    padding: 0 0.25rem;
    font-size: 1.1rem;
    line-height: 1;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.tag button:hover {
    opacity: 1;
}

/* Info Card Enhancements */
.info-card {
    background: #ffffff;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.info-card h3 {
    color: #212529;
    margin-bottom: 1.25rem;
    font-size: 1.1rem;
    font-weight: 600;
}

/* Grid Layout Improvements */
.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.25rem;
}

.info-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.info-item label {
    font-weight: 500;
    color: #495057;
    font-size: 0.9rem;
}

.info-item.full-width {
    grid-column: 1 / -1;
}

/* Input Styles */
.info-item input[type="text"],
.info-item input[type="number"] {
    padding: 0.75rem;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    font-size: 0.9rem;
    transition: border-color 0.2s;
}

.info-item input[type="text"]:focus,
.info-item input[type="number"]:focus {
    border-color: #0066cc;
    outline: none;
    box-shadow: 0 0 0 3px rgba(0,102,204,0.1);
}

.info-item input:disabled {
    background-color: #f8f9fa;
    cursor: not-allowed;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .info-grid {
        grid-template-columns: 1fr;
    }
    
    .checkbox-group {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }
}

.preview-container {
    background: #f8f9fa;
    border-radius: 12px;
    padding: 2rem;
    margin-top: 1rem;
    box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.05);
    overflow: auto;
    max-height: calc(100vh - 200px);
}

.preview-notice {
    color: #666;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    font-style: italic;
}

/* Add some styling to differentiate the preview mode */
.preview-container .creator-details-page {
    max-width: 100%;
    margin: 0;
    pointer-events: none; /* Makes it read-only */
}

/* Preview Styles */
.preview-container {
    background: #f8f9fa;
    border-radius: 12px;
    padding: 2rem;
    margin-top: 1rem;
    box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.05);
}

.creator-hero {
    display: grid;
    gap: 2rem;
    margin-bottom: 2rem;
}

.creator-card-glass {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-info-group {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.creator-profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.creator-meta {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
}

.meta-item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.stats-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social-links {
    display: flex;
    gap: 1rem;
}

.social-link {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    font-size: 1.2rem;
    transition: transform 0.2s;
}

.social-link:hover {
    transform: scale(1.1);
}

.social-link.instagram { background: #E1306C; }
.social-link.youtube { background: #FF0000; }
.social-link.tiktok { background: #000000; }
.social-link.twitter { background: #1DA1F2; }
.social-link.facebook { background: #4267B2; }
.social-link.twitch { background: #6441A4; }

.creator-section {
    background: white;
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.audience-info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-top: 1.5rem;
}

.audience-card {
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 8px;
}

.age-ranges, .location-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
}

.age-range-item, .location-tag {
    background: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.collaborations-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-top: 1.5rem;
}

.collaboration-card {
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.collab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.collab-date {
    color: #666;
    font-size: 0.9rem;
}

.preview-notice {
    color: #666;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    font-style: italic;
}

/* Platform Embeds Styles */
.platform-embeds {
    display: grid;
    gap: 2rem;
    margin-top: 1.5rem;
}

.platform-card {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.platform-info {
    padding: 1.5rem;
}

.platform-info h3 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
}

.embed-container {
    position: relative;
    width: 100%;
    background: #f8f9fa;
    border-radius: 8px;
    overflow: hidden;
}

.embed-container iframe {
    border: none;
    max-width: 100%;
}

/* Platform-specific styles */
.platform-card.youtube .embed-container {
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
}

.platform-card.youtube .embed-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.platform-card.instagram .embed-container {
    min-height: 450px;
}

.platform-card.tiktok .embed-container {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.platform-card.twitch .embed-container {
    padding-bottom: 56.25%;
    height: 0;
}

.platform-card.twitch .embed-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Add responsive adjustments */
@media (min-width: 768px) {
    .platform-embeds {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

.section-header-with-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.toggle-embeds-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9rem;
    color: #495057;
    transition: all 0.2s ease;
}

.toggle-embeds-button:hover {
    background: #e9ecef;
    border-color: #ced4da;
}

.platform-embeds {
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Followers Section Styles */
.followers-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-top: 1.5rem;
}

.follower-card {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

.follower-card:hover {
    transform: translateY(-2px);
}

.follower-icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-size: 1.5rem;
    color: white;
}

.follower-details {
    flex: 1;
}

.follower-count {
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.2;
}

.follower-platform {
    display: block;
    font-size: 0.9rem;
    color: #666;
}

.platform-link {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-size: 0.9rem;
    text-decoration: none;
    transition: all 0.2s ease;
    white-space: nowrap;
}

/* Platform-specific styles */
.follower-card.youtube .follower-icon { background: #FF0000; }
.follower-card.instagram .follower-icon { background: #E1306C; }
.follower-card.tiktok .follower-icon { background: #000000; }
.follower-card.twitter .follower-icon { background: #1DA1F2; }
.follower-card.facebook .follower-icon { background: #4267B2; }
.follower-card.twitch .follower-icon { background: #6441A4; }
.follower-card.linkedin .follower-icon { background: #0077B5; }
.follower-card.pinterest .follower-icon { background: #E60023; }
.follower-card.snapchat .follower-icon { background: #FFFC00; }

.follower-card.youtube .platform-link { 
    color: #FF0000;
    background: rgba(255, 0, 0, 0.1);
}
.follower-card.instagram .platform-link { 
    color: #E1306C;
    background: rgba(225, 48, 108, 0.1);
}
.follower-card.tiktok .platform-link { 
    color: #000000;
    background: rgba(0, 0, 0, 0.1);
}
.follower-card.twitter .platform-link { 
    color: #1DA1F2;
    background: rgba(29, 161, 242, 0.1);
}
.follower-card.facebook .platform-link { 
    color: #4267B2;
    background: rgba(66, 103, 178, 0.1);
}
.follower-card.twitch .platform-link { 
    color: #6441A4;
    background: rgba(100, 65, 164, 0.1);
}
.follower-card.linkedin .platform-link { 
    color: #0077B5;
    background: rgba(0, 119, 181, 0.1);
}
.follower-card.pinterest .platform-link { 
    color: #E60023;
    background: rgba(230, 0, 35, 0.1);
}
.follower-card.snapchat .platform-link { 
    color: #000000;
    background: rgba(255, 252, 0, 0.1);
}

.platform-link:hover {
    opacity: 0.8;
    transform: translateY(-1px);
}

@media (max-width: 768px) {
    .follower-card {
        padding: 1rem;
        gap: 1rem;
    }

    .follower-icon {
        width: 40px;
        height: 40px;
        font-size: 1.2rem;
    }

    .follower-count {
        font-size: 1.2rem;
    }
}

.settings-notice {
    color: #666;
    font-size: 0.9rem;
    padding: 1rem;
    margin: 0.5rem 0 1.5rem;
    background: #f8f9fa;
    border-radius: 8px;
    border-left: 4px solid #0d6efd;
    line-height: 1.4;
}

.completion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.profile-status {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 4px;
}

.profile-status.complete {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.profile-status.incomplete {
  background-color: #fff3e0;
  color: #ef6c00;
}

.status-icon {
  margin-right: 8px;
  font-weight: bold;
}

/* Compact Profile Completion Card Styles */
.profile-completion-card {
  background: #ffffff;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
}

.completion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.completion-header h3 {
  font-size: 14px;
  color: #24292e;
  margin: 0;
}

.profile-status {
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.profile-status.complete {
  background-color: #e6ffed;
  color: #28a745;
}

.profile-status.incomplete {
  background-color: #fff5e6;
  color: #f66a0a;
}

.completion-progress {
  margin: 12px 0;
}

.progress-bar {
  height: 4px;
  background: #eaecef;
  border-radius: 2px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: #2ea44f;
  transition: width 0.3s ease;
}

.completion-percentage {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
}

.requirements-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 8px;
  margin-top: 12px;
}

.requirement-item {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border: 1px solid #eaecef;
  border-radius: 4px;
  font-size: 12px;
  background: #f8f9fa;
}

.requirement-item.met {
  background: #f0f7f0;
  border-color: #c3e6cb;
}

.requirement-status {
  margin-right: 6px;
  color: #666;
}

.requirement-item.met .requirement-status {
  color: #28a745;
}

.requirement-details {
  overflow: hidden;
}

.requirement-name {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.requirement-description {
  display: none;
  font-size: 11px;
  color: #666;
  margin-top: 2px;
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  .profile-completion-card {
    padding: 12px;
  }

  .requirements-list {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .requirement-item:hover .requirement-description {
    display: block;
  }
}

@media (max-width: 480px) {
  .requirements-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Creator Offers Tab Styles */
.create-button {
    padding: 0.75rem 1.5rem;
    background-color: #0066cc;
    color: white;
    border: none;
    border-radius: 6px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    transition: background-color 0.2s;
}

.create-button:hover {
    background-color: #0052a3;
}

.offer-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    transition: transform 0.2s;
}

.offer-card:hover {
    transform: translateY(-2px);
}

.offer-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
}

.offer-header h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
}

.offer-type {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    background: #f0f0f0;
    border-radius: 20px;
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 1rem;
}

.offer-delivery {
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.offer-includes h4 {
    margin: 0 0 0.5rem 0;
    font-size: 1rem;
    color: #333;
}

.offer-includes ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.offer-includes li {
    padding: 0.25rem 0;
    color: #666;
    position: relative;
    padding-left: 1.5rem;
}

.offer-includes li:before {
    content: "•";
    position: absolute;
    left: 0.5rem;
    color: #0066cc;
}

.offer-actions {
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
}

.edit-offer-button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background: #f8f9fa;
    color: #0066cc;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.edit-offer-button:hover {
    background: #e9ecef;
}

.no-offers-message {
    text-align: center;
    padding: 3rem;
    background: #f8f9fa;
    border-radius: 12px;
    color: #666;
}

.no-offers-message p:first-child {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.no-offers-message p:last-child {
    font-size: 0.9rem;
    color: #888;
}

.completion-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px;
    background: white;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.completion-text-container {
    display: flex;
    flex-direction: column;
    gap: 8px;  /* Space between title and description */
}

.completion-text {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    line-height: 1.5;
}

.completion-description {
    font-size: 14px;
    color: #666;
    margin-top: 4px;  /* Additional space above description */
}
/* Only keep essential resets and custom styles that don't conflict with Chakra */
body {
    margin: 0;
    background-color: #f8fafc!important;
}

#root {
    width: 100%;
}

/* Navigation specific styles */
.hype-header {
    width: 100%;
    background: white;
    position: fixed;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.hype-header-container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
}

/* Desktop Navigation Styles */
.hype-nav {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.nav-links a,
.nav-link {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    font-size: 0.9rem;
    transition: color 0.2s ease;
    padding: 6px 8px;
}

.auth-buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.login-button,
.logout-button {
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background: white;
    transition: all 0.2s ease;
    text-decoration: none;
    color: #333;
    font-weight: 500;
}

.signup-button {
    padding: 6px 14px;
    border: none;
    background: #000;
    color: white;
    border-radius: 6px;
    text-decoration: none;
    transition: background-color 0.2s ease;
    font-weight: 500;
    font-size: 0.9rem;
}

.signup-button:hover {
    background: #333;
    color: white;
}

.main-content {
    margin-top: 70px;
    min-height: calc(100vh - 70px);
    background-color: #f8f9fa;
}

.page-container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 40px 24px;
}

/* Mobile Navigation Styles */
@media (max-width: 768px) {
    .hype-nav {
        display: none;
    }

    .hype-nav.active {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        background: white;
        padding: 0;
        box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    }

    .nav-links {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 0;
    }

    .auth-buttons {
        flex-direction: column;
        width: 100%;
        gap: 0;
        align-items: flex-start;
    }

    .nav-links a,
    .nav-link,
    .auth-buttons a,
    .auth-buttons button {
        width: 100%;
        text-align: left;
        padding: 12px 24px;
        display: block;
        border: none;
        background: transparent;
        font-size: 16px;
        color: #333;
        border-bottom: 1px solid #f0f0f0;
    }

    .signup-button {
        background: transparent;
        color: #333;
        border-radius: 0;
    }

    /* Hover state for mobile */
    .nav-links a:hover,
    .nav-link:hover,
    .auth-buttons a:hover,
    .auth-buttons button:hover {
        background-color: #f8f9fa;
    }
}

/* Keep the mobile menu button hidden on desktop */
.mobile-menu-button {
    display: none;
}

/* Show mobile menu button only on mobile */
@media (max-width: 768px) {
    .mobile-menu-button {
        display: block;
    }
}

.hype-logo {
    font-size: 24px;
    font-weight: 800;  /* Bolder */
    color: #000;
    text-decoration: none;
    position: relative;
    padding: 4px 0;  /* Add some padding for the underline */
}

.hype-logo::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
}

.hype-logo:hover::after {
    transform: scaleX(1);
}

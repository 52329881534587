.partnership-container {
    display: flex;
    flex-direction: column;
    height: 670px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);

}

.tabs {
    display: flex;
    border-bottom: 1px solid #e5e7eb;
    padding: 0 1rem;
    background: #f9fafb;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.tab {
    padding: 1rem 1.5rem;
    border: none;
    background: none;
    cursor: pointer;
    font-weight: 500;
    color: #6b7280;
    border-bottom: 2px solid transparent;
    transition: all 0.2s;
}

.tab:hover {
    color: #2563eb;
}

.tab.active {
    color: #2563eb;
    border-bottom-color: #2563eb;
}

.partnership-details {
    padding: 1rem;
    overflow-y: auto;
    color: #374151;
    font-size: 0.95rem;
}

.details-section {
    margin-bottom: 1.5rem;
}

.details-section h2 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.details-section h3 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
}

.subtitle {
    color: #6b7280;
    margin-bottom: 1rem;
}

.offer-details {
    margin-top: 0.5rem;
}

.offer-details p {
    margin: 0.25rem 0;
}

.status {
    color: #2563eb;
}

.action-buttons {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.action-btn {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.action-btn.primary {
    background: #2563eb;
    color: white;
    border: none;
}

.action-btn.secondary {
    background: white;
    color: #4b5563;
    border: 1px solid #e5e7eb;
}

.details-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.message-thread {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 600px;
    background-color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

.messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: white;
    height: calc(100% - 120px);
    max-height: 500px;
}

.messages-wrapper {
    display: flex;
    flex-direction: column;
}

.message {
    display: flex;
    flex-direction: column;
    max-width: 70%;
    margin-left: 0;
    margin-right: auto;
    padding: 0 1rem;
}

.message.sent,
.message.received {
    margin-left: 0;
    margin-right: auto;
    text-align: left;
}

.message-content {
    padding: 0;
    border-radius: 0;
    background: transparent;
    color: #111827;
    position: relative;
    font-size: 0.9375rem;
    line-height: 1.5;
    letter-spacing: -0.01em;
}

.message.sent .message-content {
    background: transparent;
    color: #111827;
}

.message-content p {
    margin: 0;
    font-size: 0.95rem;
    text-align: left;
}

.message-time {
    font-size: 0.75rem;
    color: #9ca3af;
    margin-top: 6px;
    letter-spacing: -0.01em;
}

.message.sent .message-time {
    color: #9ca3af;
}

.message-input-container {
    padding: 16px;
    border-top: 1px solid #E5E7EB;
    background-color: white;
    position: relative;
    bottom: 0;
    width: 100%;
}

.formatting-toolbar {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #e5e7eb;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    background: #f9fafb;
}

.format-button {
    padding: 0.25rem 0.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #6b7280;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    border-radius: 4px;
}

.format-button:hover {
    background: #f3f4f6;
    color: #111827;
}

.format-button.active {
    background: #e5e7eb;
    color: #111827;
}

.message-input {
    width: 100%;
    border: none;
    resize: none;
    padding: 0.5rem 0;
    font-size: 0.9375rem;
    line-height: 1.5;
    outline: none;
    min-height: 40px;
    max-height: 120px;
}

.message-input:focus {
    outline: none;
}

.message-input::placeholder {
    color: #9CA3AF;
}

.send-button {
    align-self: flex-end;
    padding: 0.5rem 1rem;
    background: #f3f4f6;
    color: #111827;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
}

.send-button:hover {
    background: #e5e7eb;
}

.send-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.message-sender {
    font-size: 0.8125rem;
    font-weight: 500;
    color: #6b7280;
    margin-bottom: 4px;
    letter-spacing: -0.01em;
}

.message.sent .message-sender {
    color: #6b7280;
}

.message-content {
    padding: 0.75rem;
}

.message.optimistic {
    opacity: 0.7;
}

/* Mobile optimizations */
@media (max-width: 768px) {
    .message {
        max-width: 85%; /* Wider messages on mobile */
        padding: 0 0.5rem; /* Less horizontal padding */
    }

    .messages-container {
        padding: 10px; /* Less padding on mobile */
        height: calc(100vh - 240px) !important; /* Adjust for mobile keyboard */
    }

    .message-input-container {
        padding: 8px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
    }

    .formatting-toolbar {
        padding: 0.25rem;
        gap: 0.25rem;
    }

    .message-input {
        min-height: 60px; /* Smaller input height on mobile */
        max-height: 120px;
        padding: 0.75rem;
        font-size: 1rem; /* Slightly larger font for better typing */
    }

    .send-button {
        padding: 0.5rem 0.75rem;
    }

    .partnership-container {
        height: 100vh; /* Full height on mobile */
        border-radius: 0; /* Remove border radius */
    }

    .input-row {
        display: flex;
        gap: 8px;
        align-items: flex-start;
    }

    /* Improve touch targets */
    .format-button {
        padding: 0.5rem;
        font-size: 1.1rem;
    }

    /* Adjust message spacing */
    .messages-wrapper {
        gap: 8px;
    }

    /* Improve readability */
    .message-content p {
        font-size: 1rem;
    }

    .message-sender {
        font-size: 0.875rem;
    }

    .message-time {
        font-size: 0.75rem;
    }
}

.message-thread-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

.messages-list {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: white;
}

/* Date header */
.date-header {
    text-align: center;
    color: #6B7280;
    font-size: 14px;
    font-weight: 500;
    margin: 24px 0 16px;
    position: relative;
}

/* Message item */
.message-item {
    margin-bottom: 24px;
}

/* Message header with avatar and name */
.message-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.message-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: #E5E7EB;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #4B5563;
    font-size: 14px;
}

.message-sender-info {
    display: flex;
    flex-direction: column;
}

.message-sender-name {
    font-weight: 500;
    font-size: 14px;
    color: #111827;
}

.message-time {
    font-size: 12px;
    color: #6B7280;
}

/* Message body */
.message-body {
    padding-left: 48px;
    font-size: 14px;
    line-height: 1.5;
    color: #374151;
}

/* Message controls */
.message-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.formatting-buttons {
    display: flex;
    gap: 8px;
}

.format-button {
    background: none;
    border: none;
    color: #6B7280;
    cursor: pointer;
    padding: 6px;
    border-radius: 4px;
}

.format-button:hover {
    background-color: #F3F4F6;
}

.send-controls {
    display: flex;
    gap: 8px;
}

.settings-button {
    background: none;
    border: none;
    color: #6B7280;
    cursor: pointer;
    padding: 6px;
    border-radius: 4px;
}

.send-button {
    background-color: #F3F4F6;
    border: none;
    color: #6B7280;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.send-button:hover:not(:disabled) {
    background-color: #E5E7EB;
}

.send-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Date groups */
.message-date-group {
    margin-bottom: 24px;
}

/* Scrollbar styling */
.messages-container::-webkit-scrollbar {
    width: 6px;
}

.messages-container::-webkit-scrollbar-track {
    background: transparent;
}

.messages-container::-webkit-scrollbar-thumb {
    background: #E5E7EB;
    border-radius: 3px;
}

.messages-container::-webkit-scrollbar-thumb:hover {
    background: #D1D5DB;
}

/* Date dividers */
.date-divider {
    color: #718096;
    font-size: 13px;
    text-align: center;
    margin: 24px 0 16px;
    position: relative;
}

.date-divider::before,
.date-divider::after {
    content: "";
    position: absolute;
    top: 50%;
    width: calc(50% - 60px);
    height: 1px;
    background-color: #E2E8F0;
}

.date-divider::before {
    left: 0;
}

.date-divider::after {
    right: 0;
}

/* Message styling */
.message-wrapper {
    margin-bottom: 20px;
}

.message-sender {
    font-size: 14px;
    font-weight: 500;
    color: #4A5568;
    margin-bottom: 4px;
}

.message-content {
    font-size: 15px;
    line-height: 1.5;
    color: #1A202C;
}

.message-time {
    font-size: 12px;
    color: #A0AEC0;
    margin-top: 4px;
}

/* Message quote styling (like in the image) */
.message-quote {
    border-left: 2px solid #E2E8F0;
    padding-left: 12px;
    margin: 8px 0;
    color: #718096;
}

/* Input area */
.message-input-container {
    border-top: 1px solid #E2E8F0;
    padding: 16px;
    background-color: white;
}

.message-input {
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    font-size: 15px;
    min-height: 24px;
    max-height: 120px;
    padding: 0;
    font-family: inherit;
}

.message-input:focus {
    outline: none;
    box-shadow: none;
}

.send-button {
    background-color: #3182CE;
    color: white;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.send-button:hover {
    background-color: #2B6CB0;
}

.send-button:disabled {
    background-color: #A0AEC0;
    cursor: not-allowed;
}

/* System messages (like refunds, contract ended) */
.system-message {
    background-color: #F7FAFC;
    border-radius: 8px;
    padding: 12px 16px;
    margin: 16px 0;
}

.system-message-header {
    font-weight: 500;
    color: #1A202C;
    margin-bottom: 4px;
}

.system-message-content {
    color: #4A5568;
}

.system-message-time {
    font-size: 12px;
    color: #A0AEC0;
    margin-top: 4px;
}

.system-message-action {
    color: #38A169;
    font-weight: 500;
    margin-top: 8px;
    cursor: pointer;
}

/* Avatar styling */
.avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: #E2E8F0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #4A5568;
    font-size: 14px;
}

.avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

/* Day headers */
.day-header {
    color: #718096;
    font-size: 13px;
    padding: 16px 0 8px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

/* Empty state */
.empty-messages {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #718096;
    text-align: center;
    padding: 20px;
}

/* Loading state */
.loading-messages {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

/* Formatting toolbar */
.formatting-toolbar {
    display: flex;
    gap: 8px;
}

.formatting-button {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #718096;
    background: transparent;
    border: none;
    cursor: pointer;
}

.formatting-button:hover {
    background-color: #F7FAFC;
    color: #4A5568;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .message-input-container {
        padding: 12px;
    }
    
    .messages-list {
        padding: 0 12px;
    }
}

/* Tabs styling */
.message-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.tab-list {
    display: flex;
    border-bottom: 1px solid #E5E7EB;
    background-color: white;
}

.tab {
    flex: 1;
    padding: 16px;
    text-align: center;
    font-weight: 500;
    color: #6B7280;
    cursor: pointer;
    transition: all 0.2s;
    border-bottom: 2px solid transparent;
}

.tab:hover {
    color: #3B82F6;
}

.tab.active {
    color: #3B82F6;
    border-bottom-color: #3B82F6;
}

/* Tab panels */
.project-details-panel,
.messages-panel {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.messages-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Messages list */
.messages-list {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: white;
}

/* Date header */
.date-header {
    text-align: center;
    color: #6B7280;
    font-size: 14px;
    font-weight: 500;
    margin: 24px 0 16px;
    position: relative;
}

/* Message item */
.message-item {
    margin-bottom: 24px;
}

/* Message header with avatar and name */
.message-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.message-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: #E5E7EB;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #4B5563;
    font-size: 14px;
}

.message-sender-info {
    display: flex;
    flex-direction: column;
}

.message-sender-name {
    font-weight: 500;
    font-size: 14px;
    color: #111827;
}

.message-time {
    font-size: 12px;
    color: #6B7280;
}

/* Message body */
.message-body {
    padding-left: 48px;
    font-size: 14px;
    line-height: 1.5;
    color: #374151;
}

/* Message input area */
.message-input-container {
    padding: 16px;
    border-top: 1px solid #E5E7EB;
    background-color: white;
    position: relative;
    bottom: 0;
    width: 100%;
}

.message-input {
    width: 100%;
    border: none;
    resize: none;
    padding: 0.5rem 0;
    font-size: 0.9375rem;
    line-height: 1.5;
    outline: none;
    min-height: 40px;
    max-height: 120px;
}

.message-input:focus {
    outline: none;
}

.message-input::placeholder {
    color: #9CA3AF;
}

/* Message controls */
.message-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.formatting-buttons {
    display: flex;
    gap: 8px;
}

.format-button {
    background: none;
    border: none;
    color: #6B7280;
    cursor: pointer;
    padding: 6px;
    border-radius: 4px;
}

.format-button:hover {
    background-color: #F3F4F6;
}

.send-controls {
    display: flex;
    gap: 8px;
}

.settings-button {
    background: none;
    border: none;
    color: #6B7280;
    cursor: pointer;
    padding: 6px;
    border-radius: 4px;
}

.send-button {
    background-color: #F3F4F6;
    border: none;
    color: #6B7280;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.send-button:hover:not(:disabled) {
    background-color: #E5E7EB;
}

.send-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Date groups */
.message-date-group {
    margin-bottom: 24px;
}

/* Scrollbar styling */
.messages-container::-webkit-scrollbar {
    width: 6px;
}

.messages-container::-webkit-scrollbar-track {
    background: transparent;
}

.messages-container::-webkit-scrollbar-thumb {
    background: #E5E7EB;
    border-radius: 3px;
}

.messages-container::-webkit-scrollbar-thumb:hover {
    background: #D1D5DB;
}

/* Loading, error, and empty states */
.loading-container,
.error-container,
.empty-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
    text-align: center;
} 
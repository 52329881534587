.login-container {
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-form {
    background: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.login-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
    color: #333;
}

.login-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.login-error {
    background-color: #fee2e2;
    color: #dc2626;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 14px;
}

.login-divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
}

.login-divider::before,
.login-divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ddd;
}

.login-divider span {
    padding: 0 10px;
    color: #666;
    font-size: 14px;
}

.login-links {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 14px;
}

.login-links a {
    color: #666;
    text-decoration: none;
}

.login-links a:hover {
    color: #333;
    text-decoration: underline;
}

.form-submit-button {
    width: 100%;
    padding: 12px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.form-submit-button:hover {
    background-color: #333;
}

.google-signin-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    background-color: white;
    border: 1px solid #dadce0;
    border-radius: 4px;
    font-size: 14px;
    color: #3c4043;
    cursor: pointer;
    transition: background-color 0.2s;
}

.google-signin-button:hover {
    background-color: #f8f9fa;
}

.google-icon {
    width: 18px;
    height: 18px;
}

.reset-instructions {
    text-align: center;
    color: #666;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.5;
}

.login-success {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    text-align: center;
}

.success-message {
    text-align: center;
    margin: 20px 0;
    padding: 20px;
    background-color: #d4edda;
    border-radius: 4px;
    color: #155724;
}

.success-message p {
    margin: 8px 0;
    line-height: 1.5;
}

.login-link {
    color: #007bff;
    text-decoration: underline;
    font-weight: 500;
}

.login-link:hover {
    color: #0056b3;
    text-decoration: none;
}

/* Style for button-like links */
a.form-submit-button {
    display: inline-block;
    width: 100%;
    padding: 12px;
    background-color: #000;
    color: white;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    margin-top: 20px;
    transition: background-color 0.2s;
}

a.form-submit-button:hover {
    background-color: #333;
    text-decoration: none;
} 
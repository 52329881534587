.profile-image-container {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px;
}

.profile-image-wrapper {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    border: 3px solid #f0f0f0;
    background: #f8f9fa;
}

.profile-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 50%;
    transform: none;
    background: #f8f9fa;
}

.profile-image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    padding: 8px;
    text-align: center;
    opacity: 0.3;
    transition: all 0.2s ease;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-image-wrapper:hover .profile-image-overlay {
    opacity: 1;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
}

.upload-button {
    color: #000;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
    padding: 6px 12px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    transition: background 0.2s ease;
    white-space: nowrap;
    line-height: 1.2;
    max-width: 90%;
}

.upload-button:hover {
    background: rgba(0, 0, 0, 0.15);
}

.upload-error {
    color: #dc3545;
    font-size: 0.9rem;
    margin-top: 8px;
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: -30px;
}
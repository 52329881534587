.creator-offer-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 32px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.creator-offer-form h2 {
    margin-bottom: 24px;
    color: #333;
}

.form-group {
    margin-bottom: 24px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 15px;
}

.form-group textarea {
    min-height: 100px;
    resize: vertical;
}

.includes-item {
    display: flex;
    gap: 12px;
    margin-bottom: 8px;
}

.remove-item {
    padding: 8px 16px;
    background: #dc3545;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}

.add-item {
    padding: 8px 16px;
    background: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 8px;
}

.form-actions {
    display: flex;
    gap: 16px;
    margin-top: 32px;
}

.cancel-button,
.submit-button {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.cancel-button {
    background: #f8f9fa;
    color: #333;
}

.submit-button {
    background: #000;
    color: white;
}

.cancel-button:hover {
    background: #e9ecef;
}

.submit-button:hover {
    background: #333;
}

.submit-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.error-message {
    padding: 12px;
    background: #dc3545;
    color: white;
    border-radius: 6px;
    margin-bottom: 24px;
}

.platforms-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 1rem;
}

.platform-checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    cursor: pointer;
}

.platform-checkbox:hover {
    background-color: #f7fafc;
}

.platform-checkbox input[type="checkbox"] {
    width: 1.2rem;
    height: 1.2rem;
}

.includes-description {
    background-color: #f8fafc;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
}

.includes-description ul {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
}

.includes-description li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.includes-description input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
}

.remove-item {
    background: none;
    border: none;
    color: #e53e3e;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 0 0.5rem;
}

.add-item {
    background: none;
    border: 1px dashed #3182ce;
    color: #3182ce;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    width: 100%;
    margin-top: 0.5rem;
}

.add-item:hover {
    background-color: #ebf8ff;
}

.gallery-upload-section {
    margin-bottom: 2rem;
}

.gallery-upload-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.upload-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #f0f0f0;
    border: 2px dashed #ccc;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;
}

.upload-button:hover {
    background-color: #e0e0e0;
    border-color: #999;
}

.gallery-preview {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
}

.gallery-photo-item {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.gallery-photo-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    display: block;
}

.delete-photo-button {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: rgba(255,255,255,0.9);
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.delete-photo-button:hover {
    background-color: #ff4444;
    color: white;
}

/* Loading state styles */
.upload-button:disabled {
    background-color: #f5f5f5;
    border-color: #ddd;
    cursor: not-allowed;
    opacity: 0.7;
} 
.verify-email-container {
    max-width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.verify-email-container h2 {
    color: #2d3748;
    margin-bottom: 1.5rem;
}

.verify-email-container p {
    color: #4a5568;
    margin-bottom: 1rem;
}

.resend-button {
    background-color: #4299e1;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    margin: 1rem 0;
    transition: background-color 0.2s;
}

.resend-button:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
}

.resend-button:hover:not(:disabled) {
    background-color: #3182ce;
}

.verification-instructions {
    text-align: left;
    margin-top: 2rem;
    padding: 1rem;
    background: #f7fafc;
    border-radius: 4px;
}

.verification-instructions h3 {
    color: #2d3748;
    margin-bottom: 1rem;
}

.verification-instructions ol {
    color: #4a5568;
    padding-left: 1.5rem;
}

.verification-instructions li {
    margin-bottom: 0.5rem;
}

.error-message {
    color: #e53e3e;
    background: #fff5f5;
    padding: 0.75rem;
    border-radius: 4px;
    margin: 1rem 0;
}

.login-button {
    display: inline-block;
    background-color: #4299e1;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    margin: 1rem 0;
    text-decoration: none;
    transition: background-color 0.2s;
}

.login-button:hover {
    background-color: #3182ce;
}

.verify-email-container button,
.verify-email-container .login-button {
    margin: 0.5rem;
} 
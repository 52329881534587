.campaign-page-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
    background: #ffffff;
}

.campaign-page-content {
    width: 100%;
}

.campaign-page-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 2rem;
}

.campaign-page-column-left,
.campaign-page-column-right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    min-width: 0;
}

.campaign-page-section {
    margin-bottom: 2rem;
}

.campaign-page-section-title {
    font-size: 1.3rem;
    color: #2B2D42;
    margin-bottom: 1rem;
    font-weight: 600;
}

.campaign-page-card {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 
        0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: transform 0.2s ease;
}

.campaign-page-card:hover {
    transform: translateY(-2px);
    box-shadow: 
        0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.campaign-page-card p {
    margin-bottom: 1rem;
    line-height: 1.6;
    color: #4A4E69;
    font-size: 1rem;
}

.campaign-page-card strong {
    color: #2B2D42;
    font-weight: 600;
    font-size: 1rem;
}

@media (max-width: 1024px) {
    .campaign-page-grid {
        grid-template-columns: 1fr;
    }
    
    .campaign-page-header {
        padding: 2rem;
    }
}

.campaign-page-header {
    background: linear-gradient(45deg, #2B2D42 30%, #4A4E69 90%);
    padding: 3rem 4rem;
    border-radius: 20px;
    color: white;
    margin-bottom: 2rem;
    box-shadow: 
        0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.campaign-page-header-content {
    flex: 1;
}

.campaign-page-header-content h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
}

.campaign-page-brand-name {
    font-size: 1.2rem;
    opacity: 0.9;
}

.campaign-page-header-actions {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}

.campaign-page-apply-btn, 
.campaign-page-back-btn {
    padding: 0.8rem 1.5rem;
    border-radius: 8px;
    font-weight: 600;
    transition: all 0.2s ease;
    cursor: pointer;
}

.campaign-page-apply-btn {
    background: white;
    color: #2B2D42;
    border: none;
}

.campaign-page-apply-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.campaign-page-back-btn {
    background: transparent;
    color: white;
    border: 2px solid white;
}

.campaign-page-back-btn:hover {
    background: rgba(255, 255, 255, 0.1);
}

.assets-section {
    margin-top: 2rem;
}

.assets-section h3 {
    font-size: 1.2rem;
    color: #2B2D42;
    margin-bottom: 1rem;
}

.assets-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
}

.asset-item {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    aspect-ratio: 1;
}

.asset-item img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.2s ease;
}

.asset-item img:hover {
    transform: scale(1.05);
}

.no-assets {
    color: #666;
    font-style: italic;
}

.ideal-creators-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.ideal-creators-item {
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;
}

.ideal-creators-item:last-child {
    border-bottom: none;
}

.ideal-creators-item strong {
    display: block;
    color: #1f2937;
    margin-bottom: 8px;
    font-size: 1.1rem;
}

.ideal-creators-item p {
    margin: 0;
    color: #1f2937;
    line-height: 1.6;
    font-size: 1rem;
}

.platform-icons {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
}

.platform-icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: #f8f9fa;
    border-radius: 8px;
    font-size: 0.95rem;
    color: #2B2D42;
}

.platform-icon i {
    font-size: 1.2rem;
}

.platform-icon .fa-instagram {
    color: #E4405F;
}

.platform-icon .fa-tiktok {
    color: #000000;
}

.platform-icon .fa-youtube {
    color: #FF0000;
}

.platform-icon .fa-twitter {
    color: #1DA1F2;
}

.platform-icon .fa-facebook {
    color: #1877F2;
}

.platform-icon .fa-linkedin {
    color: #0A66C2;
}

@media (max-width: 768px) {
    .campaign-page-container {
        padding: 1rem;
    }
    
    .campaign-page-header-content h1 {
        font-size: 2rem;
    }
    
    .campaign-page-header-actions {
        flex-direction: column;
    }
    
    .campaign-page-card {
        padding: 1.5rem;
    }
}

/* Campaign Overview Card */
.campaign-overview-details {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 0.5rem;
}

.campaign-overview-item {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.campaign-overview-label {
    font-weight: 600;
    color: #4A4E69;
    min-width: 140px;
}

.campaign-overview-value {
    color: #2B2D42;
}

/* Commission Card */
.commission-card {
    background: #f8fafc;
    border-radius: 8px;
    padding: 1.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    height: 100%;
    min-height: 230px;  /* Match the height of campaign overview card */
    justify-content: center;
    align-items: center;
    text-align: center;
    border-left: 4px solid #2B2D42;
}

.commission-header {
    font-size: 1.1rem;
    color: #64748b;
}

.commission-value {
    display: flex;
    align-items: baseline;
    gap: 0.25rem;
    margin: 0.5rem 0;
}

.commission-amount {
    font-size: 3.5rem;
    font-weight: 700;
    color: #1e293b;
    line-height: 1;
}

.commission-currency {
    font-size: 2.5rem;
    font-weight: 600;
    color: #475569;
}

.commission-type {
    font-size: 1rem;
    color: #64748b;
}

/* Add these new styles at the end of your existing CSS file */

/* Target specifically the first cards in each column */
.campaign-page-column-left .campaign-page-section:first-child .campaign-page-card,
.campaign-page-column-right .campaign-page-section:first-child .campaign-page-card {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Ensure the commission card inside the card container matches height */
.campaign-page-column-right .campaign-page-section:first-child .campaign-page-card .commission-card {
    min-height: unset;
    height: 100%;
}

/* Remove margin bottom from paragraphs in the overview card */
.campaign-page-column-left .campaign-page-section:first-child .campaign-page-card p:last-child {
    margin-bottom: 0;
} 
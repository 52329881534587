.campaign-details-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 32px;
    background-color: #f9fafb;
}

/* Campaign Header Section */
.campaign-header-section {
    background: white;
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 32px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.campaign-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
}

.campaign-title h1 {
    font-size: 28px;
    font-weight: 600;
    color: #111827;
    margin: 0;
}

.status-badge {
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
}

.status-active {
    background-color: #dcfce7;
    color: #166534;
}

.campaign-subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.campaign-subtitle h2 {
    font-size: 18px;
    color: #4b5563;
    margin: 0;
}

.campaign-timing {
    margin: 8px 0 0 0;
    display: flex;
    align-items: center;
    gap: 8px;
}

.timing-label {
    font-size: 15px;
    color: #6b7280;
    font-weight: 500;
}

.campaign-dates {
    font-size: 15px;
    color: #111827;
    font-weight: 500;
}

/* Content Grid Layout */
.campaign-content-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 32px;
}

/* Main Content Area */
.campaign-main-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

/* Metrics Section */
.metrics-section {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.metrics-section h3 {
    margin: 0 0 20px 0;
    font-size: 18px;
    color: #111827;
}

.metrics-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.metric-card {
    background: #f8fafc;
    padding: 16px;
    border-radius: 8px;
    text-align: center;
}

.metric-card h3 {
    font-size: 14px;
    color: #6b7280;
    margin: 0 0 8px 0;
}

.metric-card p {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    margin: 0;
}

/* Product Section */
.product-section {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.product-section h3 {
    margin: 0 0 24px 0;
    font-size: 18px;
    color: #111827;
    font-weight: 600;
}

.product-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.product-header {
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e5e7eb;
}

.header-content h3 {
    font-size: 20px;
    color: #6b7280;
    margin: 0;
    font-weight: 500;
}

.offer-name {
    color: #111827;
    font-weight: 600;
}

.promo-tag {
    background: #f3f4f6;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 14px;
}

.promo-tag span {
    color: #6b7280;
    margin-right: 4px;
}

/* Product Grid Styling */
.product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 32px;
}

.product-detail-card {
    background: #f9fafb;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.product-detail-card label {
    font-size: 14px;
    color: #6b7280;
    font-weight: 500;
}

.product-detail-card p {
    margin: 0;
    font-size: 16px;
    color: #111827;
    font-weight: 500;
}

.product-detail-card .promo-code {
    font-family: monospace;
    font-size: 16px;
    color: #4f46e5;
    font-weight: 600;
}

.product-description-block {
    background: #f9fafb;
    padding: 20px;
    border-radius: 8px;
}

.product-description-block label {
    display: block;
    font-size: 14px;
    color: #6b7280;
    margin-bottom: 12px;
    font-weight: 500;
}

.product-description-block p {
    margin: 0;
    font-size: 15px;
    color: #374151;
    line-height: 1.6;
}

/* Requirements Section */
.requirements-section {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.requirements-header {
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e5e7eb;
}

.requirements-header h3 {
    font-size: 20px;
    color: #111827;
    margin: 0;
    font-weight: 600;
}

.requirements-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.requirement-block {
    background: #f9fafb;
    border-radius: 8px;
    padding: 24px;
}

/* Audience Details */
.audience-details {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.audience-card {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.audience-card label {
    display: block;
    font-size: 14px;
    color: #6b7280;
    margin-bottom: 8px;
    font-weight: 500;
}

.audience-card p {
    margin: 0;
    font-size: 15px;
    color: #111827;
    line-height: 1.5;
}

/* Interests Tags */
.interests-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
}

.interest-tag {
    background: #eef2ff;
    color: #4f46e5;
    padding: 4px 10px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 500;
}

/* Responsive Design */
@media (max-width: 768px) {
    .requirements-section {
        padding: 20px;
    }

    .requirement-block {
        padding: 20px;
    }

    .audience-card {
        padding: 16px;
    }
}

/* Sidebar */
.campaign-sidebar {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.affiliate-section,
.commission-section,
.quick-links {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.link-container {
    display: flex;
    gap: 12px;
    margin-top: 16px;
}

.link-container input {
    flex: 1;
    padding: 8px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 14px;
}

.copy-button {
    padding: 8px 16px;
    background-color: #4f46e5;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
}

.copy-button:hover {
    background-color: #4338ca;
}

.commission-amount {
    display: flex;
    align-items: baseline;
    gap: 8px;
    margin-top: 16px;
}

.amount {
    font-size: 32px;
    font-weight: 600;
    color: #111827;
}

.per-conversion {
    color: #6b7280;
    font-size: 14px;
}

.product-link {
    display: inline-flex;
    align-items: center;
    color: #4f46e5;
    text-decoration: none;
    font-weight: 500;
    margin-top: 16px;
}

.product-link:hover {
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .campaign-content-grid {
        grid-template-columns: 1fr;
    }

    .metrics-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 640px) {
    .campaign-details-container {
        padding: 16px;
    }

    .metrics-grid {
        grid-template-columns: 1fr;
    }

    .detail-row {
        grid-template-columns: 1fr;
        gap: 8px;
    }

    .campaign-title {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .campaign-subtitle {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
}

.affiliate-link-section {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 32px;
}

.affiliate-link-section h3 {
    margin: 0 0 20px 0;
    font-size: 18px;
    color: #111827;
}

.affiliate-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.link-row {
    background: #f9fafb;
    padding: 20px;
    border-radius: 8px;
}

.link-input-container {
    display: flex;
    gap: 12px;
}

.link-input-container input {
    flex: 1;
    padding: 12px 16px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 14px;
    color: #374151;
    background: white;
}

.copy-button {
    padding: 12px 24px;
    background-color: #4f46e5;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
    white-space: nowrap;
}

.copy-button:hover {
    background-color: #4338ca;
    transform: translateY(-1px);
}

.copy-button:active {
    transform: translateY(0);
}

.promo-details {
    display: flex;
    align-items: center;
    padding: 16px;
    background: #f3f4f6;
    border-radius: 8px;
    margin-top: 16px;
}

.promo-code, .commission {
    display: flex;
    align-items: center;
    gap: 8px;
}

.label {
    font-size: 14px;
    color: #6b7280;
    font-weight: 500;
}

.code {
    font-size: 16px;
    color: #111827;
    font-weight: 600;
    padding: 4px 8px;
    background: #e5e7eb;
    border-radius: 4px;
}

.amount {
    font-size: 16px;
    color: #111827;
    font-weight: 600;
}

/* Responsive adjustments */
@media (max-width: 640px) {
    .promo-details {
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
    }

    .link-input-container {
        flex-direction: column;
    }

    .copy-button {
        width: 100%;
    }
}

/* Update commission section in sidebar */
.commission-section {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.commission-section h3 {
    margin: 0 0 16px 0;
    font-size: 18px;
    color: #111827;
}

.commission-content {
    background: #f8fafc;
    padding: 20px;
    border-radius: 8px;
}

.commission-amount {
    display: flex;
    align-items: baseline;
    gap: 8px;
    margin-bottom: 12px;
}

.commission-amount .amount {
    font-size: 32px;
    font-weight: 600;
    color: #111827;
}

.commission-amount .per-conversion {
    color: #6b7280;
    font-size: 14px;
}

.commission-info p {
    margin: 0;
    font-size: 14px;
    color: #6b7280;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .product-grid {
        grid-template-columns: 1fr;
    }

    .product-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .product-content {
        gap: 16px;
    }
}

/* Update product grid to 4 columns */
.product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

/* Update responsive design */
@media (max-width: 1024px) {
    .product-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 640px) {
    .product-grid {
        grid-template-columns: 1fr;
    }
}

/* Remove old promo-tag styles and add new promo-code styles */
.promo-code {
    font-family: monospace;
    font-weight: 600 !important;
    color: #4f46e5 !important;
}

/* Brand Assets Section */
.brand-assets-section {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.brand-assets-section h3 {
    margin: 0 0 20px 0;
    font-size: 18px;
    color: #111827;
    font-weight: 600;
}

.assets-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.asset-item {
    display: flex;
    gap: 16px;
    padding: 16px;
    background: #f9fafb;
    border-radius: 8px;
    transition: all 0.2s ease;
}

.asset-item:hover {
    background: #f3f4f6;
}

.asset-icon {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eef2ff;
    border-radius: 8px;
}

.asset-info {
    flex: 1;
}

.asset-info h4 {
    margin: 0 0 4px 0;
    font-size: 15px;
    font-weight: 500;
    color: #111827;
}

.asset-info p {
    margin: 0 0 8px 0;
    font-size: 13px;
    color: #6b7280;
}

.asset-download {
    font-size: 14px;
    color: #4f46e5;
    text-decoration: none;
    font-weight: 500;
    display: inline-block;
    transition: color 0.2s ease;
}

.asset-download:hover {
    color: #4338ca;
    text-decoration: underline;
}

/* Update sidebar layout */
.campaign-sidebar {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

/* Campaign Image Styles */
.campaign-image-container {
    background: #f9fafb;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
}

.campaign-image {
    width: 100%;
    height: auto;
    border-radius: 6px;
    margin-bottom: 12px;
    object-fit: cover;
}

.image-download {
    font-size: 14px;
    color: #4f46e5;
    text-decoration: none;
    font-weight: 500;
    display: inline-block;
    transition: color 0.2s ease;
}

.image-download:hover {
    color: #4338ca;
    text-decoration: underline;
}

/* Image Grid Styles */
.images-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    margin-bottom: 24px;
}

.image-item {
    background: #f9fafb;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
}

.brand-image {
    width: 100%;
    height: 300px;
    object-fit: contain;
    background: white;
    border-radius: 8px 8px 0 0;
    padding: 16px;
}

.image-download {
    display: block;
    padding: 16px;
    text-align: center;
    color: #4f46e5;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    background: white;
    transition: all 0.2s ease;
    border-top: 1px solid #e5e7eb;
}

.image-download:hover {
    background: #f3f4f6;
    color: #4338ca;
}

.section-divider {
    height: 1px;
    background: #e5e7eb;
    margin: 24px 0;
}

h4 {
    font-size: 16px;
    color: #4b5563;
    margin: 0 0 16px 0;
    font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .images-grid {
        grid-template-columns: 1fr;
    }
}

.guidelines-section {
    margin-top: 24px;
    padding: 24px 0;
}

.guidelines-section .section-title {
    font-size: 1.1rem;
    color: #334155;
    margin: 0 0 20px 0;
    font-weight: 600;
}

.guidelines-content {
    max-width: 800px;
}

.guideline-card {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    padding: 24px;
    transition: all 0.2s ease;
}

.guideline-card:hover {
    border-color: #cbd5e1;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.guideline-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f1f5f9;
    color: #475569;
}

.guideline-header svg {
    flex-shrink: 0;
}

.guideline-header h4 {
    font-size: 0.95rem;
    font-weight: 600;
    color: #334155;
    margin: 0;
}

.guidelines-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.guideline-item {
    display: flex;
    gap: 12px;
    align-items: flex-start;
}

.guideline-item .bullet {
    color: #3b82f6;
    font-size: 1.2rem;
    line-height: 1;
    margin-top: 2px;
}

.guideline-item p {
    font-size: 0.9rem;
    line-height: 1.6;
    color: #475569;
    margin: 0;
}

@media (max-width: 768px) {
    .guidelines-section {
        padding: 20px 0;
    }
    
    .guideline-card {
        padding: 20px;
    }
    
    .guidelines-list {
        gap: 12px;
    }
}
.splash-page {
  overflow-x: hidden;
}

.hero-section {
  position: relative;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  pointer-events: none;
}

.hero-content {
  flex: 1;
  max-width: 600px;
  padding-right: 4rem;
  position: relative;
  z-index: 2;
}

.hero-content h1 {
  font-size: 3.5rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  color: white;
  font-weight: 700;
}

.hero-subtitle {
  font-size: 1.25rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 2rem;
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.creator-hero-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 20px;
  box-shadow: 
    0 20px 25px -5px rgba(0, 0, 0, 0.3),
    0 10px 10px -5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  opacity: 0.9;
}

.creator-hero-image:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 25px 30px -5px rgba(0, 0, 0, 0.4),
    0 15px 15px -5px rgba(0, 0, 0, 0.3);
  opacity: 1;
}

.cta-button {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: #1a1a2e;
  background: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.2);
}

.creator-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  padding: 32px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);

  position: relative;
  z-index: 2;
}

.creator-profile {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.social-icons {
  display: flex;
  gap: 12px;
  font-size: 1.5rem;
}

.campaign-preview {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.campaign-item {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.campaign-icon {
  font-size: 2rem;
}

.campaign-text h3 {
  font-size: 1.125rem;
  margin: 0 0 4px 0;
}

.campaign-text p {
  margin: 0;
  opacity: 0.9;
}

.features-section {
  background: white;
  color: #1f2937;
  padding: 80px 24px;
}

.features-section h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 60px;
}

.features-grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.feature-card {
  text-align: center;
  padding: 32px;
  border-radius: 16px;
  background: #f9fafb;
  transition: transform 0.2s ease;
}

.feature-card:hover {
  transform: translateY(-4px);
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: 16px;
  display: block;
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 12px;
}

.feature-card p {
  color: #6b7280;
  line-height: 1.6;
}

@media (max-width: 1024px) {
  .features-section {
    padding: 60px 20px;
  }

  .features-section h2 {
    font-size: 2rem;
    margin-bottom: 40px;
  }

  .features-grid {
    grid-template-columns: 1fr;
    max-width: 500px;
  }
  
  .feature-card {
    margin-bottom: 40px;
  }
  
  .feature-card:last-child {
    margin-bottom: 0;
  }

  .feature-icon {
    font-size: 2.5rem;
    margin-bottom: 12px;
  }

  .feature-card h3 {
    font-size: 1.25rem;
    margin-bottom: 8px;
  }
}

@media (max-width: 480px) {
  .features-section {
    padding: 40px 16px;
  }

  .features-section h2 {
    font-size: 1.75rem;
    margin-bottom: 32px;
  }

  .features-grid {
    gap: 24px;
  }

  .feature-card {
    padding: 20px;
    margin-bottom: 20px;
  }
}

.hype-creators-section {
  padding: 80px 24px;
  background: #f8f9fa;
}

.hype-creators-title {
  text-align: center;
  font-size: 2.5rem !important;
  color: #1a1a1a;
  margin-bottom: 48px !important;
}

.hype-creators-grid {
  max-width: 1200px;
  margin: 0 auto;
}

.hype-creator-card {
  background: white;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.hype-creator-card:hover {
  transform: translateY(-8px);
}

.hype-creator-image-wrapper {
  width: 100%;
  height: 320px;
  overflow: hidden;
}

.hype-creator-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.hype-creator-info {
  padding: 24px;
  text-align: center;
}

.hype-creator-info h3 {
  color: #1a1a1a;
  margin-bottom: 8px;
}

.hype-follower-count {
  font-size: 1rem;
  color: #666;
  margin-bottom: 12px;
}

.hype-creator-specialty {
  display: inline-block;
  padding: 8px 16px;
  background: #f0f2f5;
  border-radius: 100px;
  font-size: 0.875rem;
  color: #1a1a1a;
}

@media (max-width: 768px) {
  .hype-creators-section {
    padding: 40px 16px;
  }

  .hype-creators-title {
    font-size: 2rem !important;
    margin-bottom: 32px !important;
  }

  .hype-creator-image-wrapper {
    height: 280px;
  }
}

.platform-benefits {
  padding: 40px 24px 80px;
  background: #fff;
}

.benefits-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 64px;
  align-items: stretch;
}

.benefit-column {
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
}

.benefit-header {
  text-align: center;
  margin-bottom: 24px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.benefit-header h2 {
  font-size: 2.5rem;
  color: #1a1a1a;
  margin-bottom: 16px;
}

.benefit-header p {
  font-size: 1.25rem;
  color: #666;
}

.benefit-features {
  display: grid;
  grid-template-rows: repeat(3, minmax(0, 1fr));
  gap: 24px;
  height: 100%;
}

.feature-item {
  background: #f8f9fa;
  padding: 24px;
  border-radius: 16px;
  transition: transform 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}

.feature-item:hover {
  transform: translateY(-4px);
}

.feature-icon {
  font-size: 2rem;
  margin-bottom: 16px;
  display: block;
}

.feature-item h3 {
  font-size: 1.25rem;
  color: #1a1a1a;
  margin-bottom: 8px;
  text-align: center;
}

.feature-item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  text-align: center;
  margin: 0;
}

@media (max-width: 768px) {
  .benefits-container {
    grid-template-columns: 1fr;
    gap: 48px;
  }
  
  .benefit-header {
    min-height: auto;
  }

  .feature-card {
    text-align: center;
    padding: 24px;
  }

  .feature-icon {
    margin: 0 auto 16px auto;
    display: block;
  }

  .feature-card h3 {
    text-align: center;
    margin-bottom: 12px;
  }

  .feature-card p {
    text-align: center;
    margin: 0 auto;
  }

  .feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  
  }
  
  .feature-item p {
    text-align: center;
  }

  .creators-section h2 {
    font-size: 2rem;
    margin-bottom: 32px;
  }

  .profile-image {
    height: 280px;
  }

  .platform-benefits {
    padding: 24px 16px 60px;
  }
}

/* Custom styles to complement Chakra UI */

/* Hero section background gradient */
.hero-gradient {
  background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
}

/* Image hover effects */
.creator-hero-image {
  transition: all 0.3s ease;
}

.creator-hero-image:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 25px 30px -5px rgba(0, 0, 0, 0.4),
    0 15px 15px -5px rgba(0, 0, 0, 0.3);
}

/* Creator cards */
.creator-profile-card {
  transition: transform 0.2s ease;
}

.creator-profile-card:hover {
  transform: translateY(-8px);
}

/* Feature items hover */
.feature-item {
  transition: transform 0.2s ease;
}

.feature-item:hover {
  transform: translateY(-4px);
}

/* Platform benefits section */
.benefit-features {
  display: grid;
  grid-template-rows: repeat(3, minmax(0, 1fr));
  gap: 24px;
  height: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .benefit-header {
    min-height: auto;
  }
}

/* Add these new styles */
.hype-category-section {
  width: 100%;
  max-width: 1200px;
  margin: 60px auto 0;
  padding: 0 20px;
  color: black;
  margin-bottom: 80px;

}

.hype-category-bubbles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 16px;
  width: 100%;
  margin: 0 auto;
}

.hype-category-bubble {
  background: white;
  padding: 16px 24px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  cursor: pointer;
}

.hype-category-bubble:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background: #f0f2f5;
}

.hype-bubble-icon {
  font-size: 1.25rem;
}

.hype-category-bubble Text:last-child {
  font-size: 1rem;
  font-weight: 500;
  color: #1a1a1a;
}

@media screen and (max-width: 768px) {
  .hype-category-section {
    padding: 0 16px;
  }

  .hype-category-bubbles {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  .hype-category-bubble {
    padding: 12px 20px;
  }
}

/* Mobile adjustments for hero section */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    padding: 2rem 1.5rem;
    gap: 2rem;
  }

  .hero-content {
    padding-right: 0;
    text-align: center;
  }

  .hero-content h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  .hero-subtitle {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .hero-image {
    width: 100%;
    padding: 0 1rem;
  }

  .creator-hero-image {
    max-width: 100%;
  }

  /* Adjust features section */
  .features-section {
    padding: 3rem 1.5rem;
  }

  /* Adjust creators section */
  .creators-section {
    padding: 3rem 1.5rem;
  }

  .creators-section h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .creators-subtitle {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  /* Adjust platform benefits */
  .platform-benefits {
    padding: 3rem 1.5rem;
  }

  .benefit-header h2 {
    font-size: 2rem;
  }

  .benefit-header p {
    font-size: 1rem;
  }

  /* Adjust category bubbles */
  .hype-category-bubbles {
    padding: 0 0.5rem;
  }

  .hype-category-bubble {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  }
}

/* Additional tablet breakpoint */
@media (max-width: 1024px) and (min-width: 769px) {
  .hero-section {
    padding: 3rem 2rem;
  }

  .hero-content h1 {
    font-size: 3rem;
  }

  .hero-subtitle {
    font-size: 1.1rem;
  }
}

.social-proof-section {
  background: linear-gradient(180deg, #f7fafc 0%, #fff 100%);
  padding: 4rem 0;
}

.creator-showcase-card {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: transform 0.2s;
  cursor: pointer;
  padding: 12px;
}

.creator-showcase-card:hover {
  transform: translateY(-4px);
}

.creator-showcase-card iframe {
  border-radius: 16px !important;
  width: 100% !important;
  height: 100% !important;
}

.creator-info-card {
  background: white;
  border-radius: 1rem;
  padding: 1rem;
}

.creator-info-card text {
  color: black;
}

/* Ensure text is always visible */
.creator-showcase-card .chakra-text {
  color: #1A202C;
}

/* Make secondary text darker but still distinct */
.creator-showcase-card .chakra-text[color="gray.800"] {
  color: #2D3748;
}

/* Ensure emoji icons are visible */
.creator-showcase-card span {
  color: #2D3748;
}

/* Ensure TikTok embed container maintains aspect ratio */
.tiktok-embed-container {
  position: relative;
  padding-bottom: 177.77%;
  height: 0;
  overflow: hidden;
}

.tiktok-embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Add smooth transition for hover effects */
.creator-showcase-card * {
  transition: all 0.2s ease-in-out;
}

.tiktok-embed {
  border-radius: 1rem;
  overflow: hidden;
}

/* Add specific styles for TikTok cards */
.creator-showcase-card.tiktok {
  background: transparent;
  box-shadow: none;
}

.creator-showcase-card.tiktok:hover {
  transform: none;
}

@media (max-width: 48em) {
  .creator-showcase-card {
    padding: 12px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .creator-info-card {
    padding: 1rem;
  }

  .creator-info-card img {
    width: 42px;
    height: 42px;
  }

  .social-proof-section {
    padding: 0 16px;
  }
}

.how-it-works-section {
  background-color: #f8f9fa;
  overflow: hidden;
  position: relative;
}

.how-it-works-section img {
  transition: transform 0.3s ease;
}

.how-it-works-section img:hover {
  transform: scale(1.02);
}

.step-content {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transform: translateX(50px);
  position: absolute;
  width: 100%;
}

.step-content.active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

/* Underline animation for "valuable" */
@keyframes drawUnderline {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.hero-section h1 span[class*="underline"] {
  position: relative;
  display: inline-block;
}

.hero-section h1 span[class*="underline"]::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #B2F5EA;
  animation: drawUnderline 1s ease-out forwards;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2.5rem;
  }
} 
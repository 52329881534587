/* Creator Profile Form Styles */

/* Main container */
.creator-profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8fafc;
  padding: 20px;
}

/* Form container */
.creator-form-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

/* Form wrapper */
.form-wrapper {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

/* Form header */
.form-header {
  padding: 24px 32px 16px;
  border-bottom: 1px solid #f0f0f0;
}

.form-header h2 {
  margin-bottom: 0.25rem !important;
  color: #3182ce !important;
  font-weight: 600 !important;
  font-size: 1.25rem !important;
}

.progress-bar {
  height: 8px !important;
  border-radius: 20px;
  margin-top: 12px;
  overflow: hidden;
}

.chakra-progress__track {
  background-color: #f0f0f0 !important;
}

.chakra-progress__indicator {
  background-color: #FF6B6B !important;
}

/* Form content */
.form-content {
  padding: 32px;
}

.form-step {
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.step-heading {
  font-size: 28px !important;
  margin-bottom: 8px !important;
  font-weight: 700 !important;
  color: #333 !important;
}

.step-description {
  margin-bottom: 32px !important;
  color: #6b7280 !important;
  font-size: 16px !important;
}

/* Form fields */
.form-field {
  margin-bottom: 24px !important;
}

.form-field label {
  margin-bottom: 8px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #4b5563 !important;
}

.form-input, 
.form-select, 
.form-textarea {
  border-radius: 12px !important;
  border: 2px solid #e5e7eb !important;
  padding: 16px !important;
  font-size: 16px !important;
  transition: all 0.2s ease !important;
  background-color: #f9fafb !important;
}

.form-input:focus, 
.form-select:focus, 
.form-textarea:focus {
  border-color: #FF6B6B !important;
  box-shadow: 0 0 0 1px #FF6B6B !important;
  background-color: white !important;
}

.form-textarea {
  min-height: 100px !important;
  resize: vertical;
}

.field-hint {
  margin-top: 6px !important;
  font-size: 14px !important;
  color: #6b7280 !important;
}

/* Form footer */
.form-footer {
  padding: 24px 32px;
  border-top: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.prev-button, .next-button {
  min-width: 120px !important;
  height: 56px !important;
  border-radius: 12px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.next-button {
  background-color: #000000 !important;
  color: white !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.next-button:hover {
  background-color: #333333 !important;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15) !important;
}

.prev-button {
  background-color: transparent !important;
  color: #4b5563 !important;
  border: 1px solid #e5e7eb !important;
}

.prev-button:hover {
  background-color: #f9fafb !important;
}

/* Avatar upload */
.avatar-upload-container {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.avatar-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar-preview img {
  width: 120px !important;
  height: 120px !important;
  border-radius: 50% !important;
  object-fit: cover;
  border: 4px solid white !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.avatar-upload {
  display: flex;
  justify-content: center;
}

.upload-avatar-btn {
  background-color: #f9fafb !important;
  border: 2px dashed #e5e7eb !important;
  border-radius: 12px !important;
  padding: 32px !important;
  color: #6b7280 !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.2s ease !important;
  width: 100% !important;
  max-width: 300px !important;
}

.upload-avatar-btn:hover {
  border-color: #FF6B6B !important;
  color: #FF6B6B !important;
  background-color: #fff5f5 !important;
}

.remove-avatar-btn {
  margin-top: 12px !important;
  color: #6b7280 !important;
}

/* Name and country row */
.name-country-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 24px;
}

@media (max-width: 480px) {
  .name-country-row {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

/* Content categories row */
.content-categories-row {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  margin-top: 16px;
}

/* Fun progress indicator */
.step-indicator {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.step-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #e5e7eb;
  margin: 0 6px;
  transition: all 0.3s ease;
}

.step-dot.active {
  background-color: #FF6B6B;
  transform: scale(1.2);
}

/* Fun animations */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-10px);}
  60% {transform: translateY(-5px);}
}

.next-button:focus {
  animation: bounce 1s;
}

/* Method selection cards */
.method-card {
  border: 2px solid #e5e7eb;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 16px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.method-card:hover {
  border-color: #FF6B6B;
  transform: translateY(-2px);
}

.method-card.selected {
  border-color: #FF6B6B;
  background-color: rgba(255, 107, 107, 0.05);
}

.method-card-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.method-card-description {
  color: #6b7280;
  font-size: 14px;
}

/* URL input styling */
.url-input-container {
  position: relative;
  margin-top: 16px;
}

.url-input {
  padding-left: 40px !important;
}

.url-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6b7280;
}

/* Generate button */
.generate-button {
  background-color: #FF6B6B !important;
  color: white !important;
  border-radius: 12px !important;
  height: 48px !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
}

.generate-button:hover {
  background-color: #ff5252 !important;
  transform: translateY(-2px);
}

/* Divider styling */
.chakra-divider {
  margin: 24px 0 !important;
  opacity: 0.6 !important;
}

/* Error alert styling */
.chakra-alert {
  border-radius: 12px !important;
}

/* Social media section */
.social-media-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.social-media-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border-radius: 12px;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
}

/* Checkbox grid */
.checkbox-container {
  margin-top: 8px;
}

.checkbox-grid-compact {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 12px;
}

.checkbox-item {
  padding: 8px 12px !important;
  border-radius: 8px !important;
  background-color: #f9fafb !important;
  border: 1px solid #e5e7eb !important;
  transition: all 0.2s ease !important;
}

.checkbox-item[data-checked] {
  background-color: #000000 !important;
  color: white !important;
  border-color: #000000 !important;
}

/* Collaboration items */
.collaborations-list {
  margin-top: 16px;
}

.collaboration-item {
  border-radius: 12px !important;
  transition: all 0.2s ease !important;
}

.collaboration-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .form-content {
    padding: 24px 20px;
  }
  
  .form-header, .form-footer {
    padding: 20px;
  }
  
  .checkbox-grid-compact {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
  
  .prev-button, .next-button {
    min-width: auto !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

/* Content categories row */
.content-categories-row {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  margin-top: 16px;
}

/* Fun progress indicator */
.step-indicator {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.step-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #e5e7eb;
  margin: 0 6px;
  transition: all 0.3s ease;
}

.step-dot.active {
  background-color: #FF6B6B;
  transform: scale(1.2);
}

/* Fun animations */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-10px);}
  60% {transform: translateY(-5px);}
}

.next-button:focus {
  animation: bounce 1s;
}

/* Method selection cards */
.method-card {
  border: 2px solid #e5e7eb;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 16px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.method-card:hover {
  border-color: #FF6B6B;
  transform: translateY(-2px);
}

.method-card.selected {
  border-color: #FF6B6B;
  background-color: rgba(255, 107, 107, 0.05);
}

.method-card-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.method-card-description {
  color: #6b7280;
  font-size: 14px;
}

/* URL input styling */
.url-input-container {
  position: relative;
  margin-top: 16px;
}

.url-input {
  padding-left: 40px !important;
}

.url-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6b7280;
}

/* Generate button */
.generate-button {
  background-color: #FF6B6B !important;
  color: white !important;
  border-radius: 12px !important;
  height: 48px !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
}

.generate-button:hover {
  background-color: #ff5252 !important;
  transform: translateY(-2px);
}

/* Divider styling */
.chakra-divider {
  margin: 24px 0 !important;
  opacity: 0.6 !important;
}

/* Error alert styling */
.chakra-alert {
  border-radius: 12px !important;
} 